import React, { FC } from 'react';

import TableRowPlaceholder, { CellDefinition } from '../TableRowPlaceholder/TableRowPlaceholder';

export interface TableRowPlaceholderProps {
  cells: CellDefinition[];
  rows: number;
}

const TableRowsPlaceholder: FC<TableRowPlaceholderProps> = ({ cells, rows }) => (
  <>
    {Array(rows)
      .fill(0)
      .map((_, index) => (
        <TableRowPlaceholder cells={cells} key={String(index)} />
      ))}
  </>
);

export default TableRowsPlaceholder;
