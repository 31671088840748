import React, { FC, useMemo } from 'react';
import { Collapse } from '@mui/material';
import Alert from '@mui/material/Alert';
import { KnownCodeError } from 'api';
import { useTranslation } from 'react-i18next';

export interface ApiErrorMessageProps {
  error: Error | undefined | null;
}

const ApiErrorMessage: FC<ApiErrorMessageProps> = ({ error }) => {
  const { i18n } = useTranslation();
  const message = useMemo((): string | null => {
    return KnownCodeError.getMessage(error, 'pages.weekDetails.edit.errors', i18n);
  }, [i18n, error]);

  return (
    <Collapse in={error !== undefined && error !== null}>
      <Alert severity="error" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Collapse>
  );
};

export default ApiErrorMessage;
