import {State} from '..';
import {StoreApi} from 'zustand';

type StateResolver<T> = (state: T) => T | Partial<T>;

type GetState<T extends object> = StoreApi<T>['getState'];
type SetState<T extends object> = StoreApi<T>['setState'];

export const createSetAction =
  <T extends object>(set: SetState<State>, slice: keyof State) =>
    (data: T | StateResolver<T>) => {
      if (typeof data === 'function') {
        set(state => ({[slice]: {...state[slice], ...(data as StateResolver<T>)(state[slice] as T)}}));
      } else {
        set(state => ({[slice]: {...state[slice], ...data}}));
      }
    };

export const createGetAction =
  <Slice extends object>(get: GetState<State>, slice: keyof State): GetState<Slice> =>
    () =>
      get()[slice] as never as Slice;

type InitialStateCreator<SliceState extends object> = (
  set: SetState<SliceState>,
  get: GetState<SliceState>,
  globalSet: SetState<State>,
  globalGet: GetState<State>,
) => SliceState;

export const getSliceCreator =
  <SliceState extends object>(sliceName: keyof State, createInitialState: InitialStateCreator<SliceState>) =>
    (globalSet: SetState<State>, globalGet: GetState<State>): Partial<State> => {
      const set = createSetAction<SliceState>(globalSet, sliceName);
      const get = createGetAction<SliceState>(globalGet, sliceName);

      return {
        [sliceName]: createInitialState(set, get, globalSet, globalGet),
      };
    };
