import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import { useDataGridContext } from 'components/ControlledDataGrid/context/DataGridContext/DataGridContext';
import { TimesheetDto } from 'types';

import { getRowId } from '../../../../utils';

export interface SelectAllRelevantRowsButtonProps {
  clearFilters: () => void;
}

const SelectAllAcceptedButton: FC<SelectAllRelevantRowsButtonProps> = ({ clearFilters }) => {
  const { t } = useTranslation();
  const { onSelectionModelChange, data } = useDataGridContext<TimesheetDto>();

  const handleSelect = useCallback(() => {
    clearFilters();

    onSelectionModelChange(data.filter((timesheet) => timesheet.accepted).map(getRowId));
  }, [onSelectionModelChange, data, clearFilters]);

  return (
    <Button startIcon={<PlaylistAddIcon />} onClick={handleSelect}>
      {t('pages.weekDetails.actions.selectAllAccepted')}
    </Button>
  );
};

export default SelectAllAcceptedButton;
