import { DateTime } from 'luxon';
import { Api } from 'api';
import { useCallback, useEffect, useState } from 'react';

const useGetCurrentWeekForReport = (): [string|null, boolean] => {
  const [currentDate, setCurrentDate] = useState<string|null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchCurrentUser = useCallback(
    () => {
        setLoading(true);
        Api.auth.fetchCurrentUser().then(response => {
            const todayDate = DateTime.now();
            const currentWeek = response.currentWeek;
            if (currentWeek !== undefined) {
                setCurrentDate(todayDate.plus({weeks: currentWeek}).toFormat('kkkk-WW'));
            }
        }).finally(() => setLoading(false));
    },
    [],
  )
  
  useEffect(() => {
    fetchCurrentUser();
  }, []);

  return [currentDate, loading];
};

export default useGetCurrentWeekForReport;
