import {TFunction} from 'react-i18next';

import TranslatedError from './TranslatedError';

class InvalidCredentialsError extends TranslatedError {
  constructor() {
    super('api.errors.invalidCredentials');
  }

  getTranslatedMessage = (t: TFunction) => t(this.message);
}

export default InvalidCredentialsError;
