import React, { FC, useCallback } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import FormikTimeField from 'components/FormikTimeField/FormikTimeField';
import SlideUpTransition from 'components/SlideUpTransition/SlideUpTransition';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import useForm from './hooks/useForm';
import DurationWatcher from './components/DurationWatcher/DurationWatcher';
import { TimesheetEditContextProvider, useTimesheetEditContext } from './context/TimesheetEditContext';
import EmployeeDetails from './components/EmployeeDetails/EmployeeDetails';
import { RouteParams } from '../../types';
import ErrorBoundary from '../../../../components/ErrorBoundary/ErrorBoundary';
import ApiErrorMessage from './components/ApiErrorMessage/ApiErrorMessage';

const TimeContainer = styled.div`
  display: grid;
  padding-top: ${({ theme }) => theme.spacing(2)};
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  p {
    padding-bottom: ${({ theme }) => theme.spacing(1)} !important;
  }
`;

export interface TimesheetUpsertFormProps {}

const TimesheetEdit: FC<TimesheetUpsertFormProps> & { ContextProvider: typeof TimesheetEditContextProvider } = () => {
  const { timesheet, open, closeDialog, openedCount, date } = useTimesheetEditContext();
  const { t } = useTranslation();
  const { department } = useParams<RouteParams>();
  const { initialValues, validationSchema, onSubmit, didNotWork, isLoading, error } = useForm(
    timesheet,
    date,
    department,
    closeDialog,
  );
  const title = timesheet === undefined ? 'pages.weekDetails.edit.titleAdd' : 'pages.weekDetails.edit.titleEdit';

  const onClose = useCallback(() => {
    // Do not close by escape or backdrop click
  }, []);

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={SlideUpTransition} keepMounted maxWidth="md">
      <ErrorBoundary onClose={closeDialog} key={String(openedCount)}>
        <Formik
          key={String(openedCount)}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <DialogTitle id="alert-dialog-title">{t(title)}</DialogTitle>
            <DialogContent>
              <Content>
                <ApiErrorMessage error={error} />
                <TimeContainer>
                  <FormikTimeField
                    disabled={isLoading}
                    required
                    label={t('pages.weekDetails.edit.start')}
                    name="start"
                  />
                  <FormikTimeField disabled={isLoading} required label={t('pages.weekDetails.edit.end')} name="end" />
                  <FormikTimeField
                    disabled={isLoading}
                    required
                    label={t('pages.weekDetails.edit.break')}
                    name="break"
                  />
                  <FormikTimeField disabled label={t('pages.weekDetails.edit.duration')} name="duration" />
                </TimeContainer>
                <EmployeeDetails disabled={timesheet !== undefined || isLoading} />
                <DurationWatcher />
              </Content>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                variant="contained"
                disabled={isLoading}
                loading={isLoading}
                onClick={didNotWork}
              >
                {t('pages.weekDetails.edit.didNotWork')}
              </LoadingButton>
              <div style={{flex: '1 0 0'}} />
              <Button onClick={closeDialog}>{t('pages.weekDetails.edit.cancel')}</Button>
              <LoadingButton variant="contained" type="submit" disabled={isLoading} loading={isLoading}>
                {t('pages.weekDetails.edit.save')}
              </LoadingButton>
            </DialogActions>
          </Form>
        </Formik>
      </ErrorBoundary>
    </Dialog>
  );
};

TimesheetEdit.ContextProvider = TimesheetEditContextProvider;

export default TimesheetEdit;
