import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { Api } from 'api';
import Layout from 'components/Layout/Layout';
import useOrganizationsData from 'components/OrganizationsTable/hooks/useOrganizationsData';

import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import UserForm from './components/UserForm/UserForm';
import { FETCH_USER_DETAILS_QUERY, RouteParams } from './types';

const Container = styled.div`
  max-width: 1380px;
  align-self: start;
  flex: 1;

  display: grid;
  grid-template-rows: auto 1fr;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.spacing(2)};
`;

export interface UserDetailsPageProps {}

const UserDetailsPage: FC<UserDetailsPageProps> = () => {
  const { id } = useParams<RouteParams>();
  const { data: user, isLoading: isUserLoading } = useQuery([FETCH_USER_DETAILS_QUERY, id], () => {
    if (id === 'new') {
      return Promise.resolve(undefined);
    } else {
      return Api.user.fetchUserDetails(id as string);
    }
  });
  const { data: organizations = [], isLoading: isOrganizationsListLoading } = useOrganizationsData();
  return (
    <Layout>
      <Container>
        <Breadcrumbs user={user} loading={isUserLoading} />
        {!isUserLoading && !isOrganizationsListLoading && <UserForm user={user} organizations={organizations} />}
      </Container>
    </Layout>
  );
};

export default UserDetailsPage;
