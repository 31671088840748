import React, { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import useStore from 'store';
import { UserRole } from '../../types';
import useUserHasRoles from '../../hooks/useUserHasRoles/useUserHasRoles';

export interface ProtectedRouteProps {
  children: React.ReactNode;
  noOutlet?: boolean;
  roles?: UserRole[];
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, noOutlet, roles }) => {
  const isLoggedIn = useStore((store) => store.user.isLoggedIn);
  const hasRole = useUserHasRoles(roles);

  if (isLoggedIn && hasRole) {
    return (
      <>
        {children}
        {!noOutlet && <Outlet />}
      </>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
