import React, { FC } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid/Grid';
import Paper from '@mui/material/Paper/Paper';

import warehouse from 'assets/images/warehouse.jpg';
import { RouteParams } from './types';
import SendPasswordReminderForm from './components/SendPasswordReminderForm/SendPasswordReminderForm';
import ResetPasswordForm from './components/ResetPasswordForm/ResetPasswordForm';

const GridImage = styled(Grid)`
  background-image: url(${warehouse});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
`;

export interface ForgotPasswordPageProps {}

const ForgotPasswordPage: FC<ForgotPasswordPageProps> = () => {
  const { token } = useParams<RouteParams>();

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <GridImage item xs={false} sm={4} md={7} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {token ? <ResetPasswordForm token={token} /> : <SendPasswordReminderForm />}
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordPage;
