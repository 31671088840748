import React, { FC, ReactNode, useEffect } from 'react';
import useStore from 'store';

export interface SystemConfigProviderProps {
  children: ReactNode;
}

const SystemConfigProvider: FC<SystemConfigProviderProps> = ({ children }) => {
  const loadConfig = useStore((store) => store.config.fetchConfig);

  useEffect(() => {
    loadConfig();
  }, [loadConfig]);

  return <>{children}</>;
};

export default SystemConfigProvider;
