import React, { FC, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import FormikAutocompleteSelect from 'components/FormikAutocompleteSelect/FormikAutocompleteSelect';
import useEffectOnFieldChange from 'hooks/useEffectOnFieldChange/useEffectOnFieldChange';
import { AccountDto, WorklocationDto } from 'types';
import useStore from 'store';

import { Form } from '../../types';
import ImportButton from './components/ImportButton/ImportButton';
import { useParams } from 'react-router-dom';

const Root = styled(Paper)`
  flex: 1;

  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-template-rows: 1fr;
  grid-gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

export interface AccountAndWorklocationSelectionProps {}

const AccountAndWorklocationSelection: FC<AccountAndWorklocationSelectionProps> = () => {
  const user = useStore((store) => store.user.user);
  const accounts = useStore((store) => store.accounts.accounts);
  const loading = useStore((store) => store.accounts.loading);
  const { accountId, worklocationId } = useParams<{ accountId?: string, worklocationId?: string }>();

  const {
    values: { account },
    setFieldValue,
  } = useFormikContext<Form>();

  // Clear worklocation on account change
  useEffectOnFieldChange<Form>('account', () => {
    setFieldValue('worklocation', null);
  });

  // If there is only one account force it to be selected
  useEffect(() => {
    if (accounts.length === 1) {
      setFieldValue('account', accounts[0]);
      return;
    }
    if (accountId && worklocationId) {
      setFieldValue('account', accounts.find(row => row.id === parseInt(accountId)) ?? null);
    }
  }, [setFieldValue, accounts, accountId, worklocationId]);

  useEffect(() => {
    if (accountId && worklocationId) {
      setFieldValue('worklocation', account?.worklocations?.find(row => row.id === parseInt(worklocationId)) ?? null);
    }
  }, [setFieldValue, account?.worklocations, accountId, worklocationId]);

  return (
    <Root>
      <FormikAutocompleteSelect<AccountDto, false, false, false>
        disablePortal
        options={accounts}
        name="account"
        loading={loading}
        disabled={accounts.length === 1}
        getOptionLabel={(option) => option.name}
        onChangeCapture={() => {
          setFieldValue('worklocation', null);
        }}
        renderInput={(params) => <TextField {...params} size="small" label="Account" />}
      />
      <FormikAutocompleteSelect<WorklocationDto, false, false, false>
        disablePortal
        loading={loading}
        options={account?.worklocations ?? []}
        name="worklocation"
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} size="small" label="Worklocation" />}
      />
      {user?.roles?.includes('ROLE_OTTO') && <ImportButton />}
    </Root>
  );
};

export default AccountAndWorklocationSelection;
