import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Check, Close } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { TimesheetDto } from 'types';
import DataGridNoDataOverlay from 'components/DataGridNoDataOverlay/DataGridNoDataOverlay';
import DataGridNoResultsOverlay from 'components/DataGridNoResultsOverlay/DataGridNoResultsOverlay';
import ControlledDataGrid from 'components/ControlledDataGrid/ControlledDataGrid';

import DataGridToolbar from './components/DataGridToolbar/DataGridToolbar';
import useColumns from './hooks/useColumns';
import { getRowId } from './utils';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';

const Root = styled.div`
  display: block;
  min-height: 400px;
  // Viewport Height - all other elements of the page (approximate)
  height: calc(100vh - 48px - 40px - 64px - 20px - ${({ theme }) => theme.spacing(2)});
  flex: 1;

  .MuiDataGrid-actionsCell {
    grid-gap: 0;
  }
`;

const StyledDataGrid = styled(ControlledDataGrid)`
  border: none;
`;

const StyledBooleanCellTrueIcon = styled(Check)`
  color: green !important;
`;

const StyledBooleanCellFalseIcon = styled(Close)`
  color: red !important;
`;

export interface TimesheetsTableProps {
  data: TimesheetDto[];
  weekDay?: DateTime;
  loading?: boolean;
}

export interface TimesheetsTableExtra {
  weekDay?: DateTime;
}

const initialState: GridInitialStatePro = {
  pinnedColumns: { right: ['accepted', 'actions'] },
  sorting: {
    sortModel: [{ field: 'employee.lastName', sort: 'asc' }, { field: 'date', sort: 'desc' }],
  },
};

const TimesheetsTable: FC<TimesheetsTableProps> = ({ data, weekDay, loading = false }) => {
  const columns = useColumns();
  const { t } = useTranslation();

  const extra: TimesheetsTableExtra = useMemo(() => ({ weekDay }), [weekDay]);

  return (
    <Root>
      <StyledDataGrid
        rows={data}
        extra={extra}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        loading={loading}
        disableColumnFilter
        disableColumnMenu
        initialState={initialState}
        getRowId={getRowId}
        components={{
          Toolbar: DataGridToolbar,
          NoRowsOverlay: DataGridNoDataOverlay,
          NoResultsOverlay: DataGridNoResultsOverlay,
          BooleanCellTrueIcon: StyledBooleanCellTrueIcon,
          BooleanCellFalseIcon: StyledBooleanCellFalseIcon,
        }}
        localeText={{
          booleanCellTrueLabel: t('pages.weekDetails.table.elements.accepted'),
          booleanCellFalseLabel: t('pages.weekDetails.table.elements.notAccepted'),
        }}
      />
    </Root>
  );
};

export default TimesheetsTable;
