import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';

import FormikTextField from 'components/FormikTextField/FormikTextField';
import FormikAutocompleteSelect from 'components/FormikAutocompleteSelect/FormikAutocompleteSelect';
import { OrganizationDto, OrganizationGroupDto, UserDto } from 'types';

import useForm, { OrganizationFormData } from './hooks/useForm';
import FormikSwitchField from 'components/FormikSwitchField/FormikSwitchField';

const Root = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(2)};

  form > section {
    display: flex;
    flex-direction: row;
    flex: 1;

    & > section {
      padding: ${({ theme }) => theme.spacing(2, 1)};
      display: flex;
      flex-direction: column;
      flex: 1;

      & > .MuiFormControl-root {
        margin: ${({ theme }) => theme.spacing(1, 0)};
      }
    }
  }
`;

const Actions = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: ${({ theme }) => theme.spacing(1)};

  button {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;

export interface OrganizationFormProps {
  organization?: OrganizationDto;
  users: UserDto[];
  groups: OrganizationGroupDto[];
}

const OrganizationForm: FC<OrganizationFormProps> = ({ organization, users, groups }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { initialValues, validationSchema, onSubmit, isLoading } = useForm(organization, groups);

  return (
    <Root>
      <Typography variant="h5" ml={1}>
        {organization === undefined
          ? t('pages.organizationDetails.titles.add')
          : t('pages.organizationDetails.titles.edit', organization)}
      </Typography>
      <Formik<OrganizationFormData>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <section>
            <section>
              <FormikTextField
                disabled={isLoading || organization?.nameEditable === false}
                required
                name="name"
                label={t('pages.organizationDetails.fields.name')}
                helperText={organization?.nameEditable === false ? t('pages.organizationDetails.other.nameDisabled') : undefined}
              />
            </section>
          </section>
          <section>
            <section>
              <FormikSwitchField name="active" label={t('pages.organizationDetails.fields.active')} disabled={isLoading} />
            </section>
          </section>
          <section>
            <section>
              <FormikAutocompleteSelect<UserDto, true, false, false>
                multiple
                fullWidth
                disabled={isLoading}
                options={users}
                name="users"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                renderInput={(params) => <TextField {...params} label={t('pages.organizationDetails.fields.users')} />}
              />
            </section>
          </section>
          <section>
            <section>
              <FormikAutocompleteSelect<OrganizationGroupDto, false, false, false>
                fullWidth
                disabled={isLoading}
                options={groups}
                name="group"
                getOptionLabel={(option) => `${option.name}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} label={t('pages.organizationDetails.fields.group')} />}
              />
            </section>
          </section>
          <Actions>
            <Button onClick={() => navigate('/organizations')} disabled={isLoading}>
              {t('pages.organizationDetails.actions.cancel')}
            </Button>
            <LoadingButton disabled={isLoading} loading={isLoading} variant="contained" type="submit">
              {t('pages.organizationDetails.actions.save')}
            </LoadingButton>
          </Actions>
        </Form>
      </Formik>
    </Root>
  );
};
export default OrganizationForm;
