import React, { useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import styled from 'styled-components';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

import { CircularProgress } from '@mui/material';
import { FormikValues, useFormikContext } from 'formik';
import useGetCurrentWeekForReport from 'hooks/useGetCurrentWeekForReport/useGetCurrentWeekForReport';

export interface ReportWithWeekBreadcrumpProps {
  reportName: string;
}

const Root = styled(Paper)`
  flex: 1;
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

const ReportWithWeekBreadcrump = <FormType extends FormikValues>(
  props: ReportWithWeekBreadcrumpProps
) => {
  const params = useParams<{ week?: string }>();
  const [ weekFromConfig, loading] = useGetCurrentWeekForReport();
  const {
    setFieldValue,
    values: { week }
  } = useFormikContext<FormType>();

  useEffect(() => {
    if (params.week !== undefined) {
      setFieldValue('week', params.week);
    } else if (weekFromConfig && !loading) {
      setFieldValue('week', weekFromConfig);
    }
  }, [params.week, loading]);

  return (
    <Root>
      <MuiBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
          {props.reportName}
        </Link>
        <Link underline="hover" color="inherit" component={RouterLink} to={`/customer-overview/${week}`}>
          {loading && <CircularProgress size={12} />}
          {!loading && week}
        </Link>
      </MuiBreadcrumbs>
    </Root>
  );
};

export default ReportWithWeekBreadcrump;
