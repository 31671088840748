import { FC, useEffect } from 'react';
import { useFormikContext } from 'formik';
import {DateTime, Duration} from 'luxon';
import { dateTimeToDuration, durationToDateTime } from 'utils';
import { TimesheetEditForm } from '../../hooks/useForm';

export interface DurationWatcherProps {}

const DurationWatcher: FC<DurationWatcherProps> = () => {
  const { values, setFieldValue } = useFormikContext<TimesheetEditForm>();

  useEffect(() => {
    if (
      !values.end ||
      !values.end.isValid ||
      !values.start ||
      !values.start.isValid ||
      !values.break ||
      !values.break.isValid
    ) {
      return;
    }

    // Copy current end date to new object for not mutable original value.
    let newEndDate = DateTime.fromSeconds(values.end.toUnixInteger())

    // if end date is lower than strat date should be counting as next day.
    if (newEndDate < values.start) {
      newEndDate = newEndDate.plus(Duration.fromObject({ day: 1 }));
    }

    const duration = newEndDate.diff(values.start).minus(dateTimeToDuration(values.break));

    if (!duration || !duration.isValid || Number(duration.toFormat('s')) < 0) {
      setFieldValue('duration', durationToDateTime(Duration.fromObject({ second: 0 })));
    } else {
      setFieldValue('duration', durationToDateTime(duration));
    }
  }, [values.start, values.end, values.break, setFieldValue]);

  // This is not recommended but making it "right" would make the component tree too complex
  return null;
};

export default DurationWatcher;
