import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';

import RoleChip, { getRoleName } from 'components/RoleChip/RoleChip';
import FormikTextField from 'components/FormikTextField/FormikTextField';
import FormikAutocompleteSelect from 'components/FormikAutocompleteSelect/FormikAutocompleteSelect';
import FormikSelectField from 'components/FormikSelectField/FormikSelectField';
import { ALL_USER_ROLES, OrganizationDto, UserDto, UserRole } from 'types';
import useStore from 'store';

import useForm, { UserFormData } from './hooks/useForm';
import FormikSwitchField from '../../../../components/FormikSwitchField/FormikSwitchField';

const Root = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(2)};

  form > section {
    display: flex;
    flex-direction: row;
    flex: 1;

    & > section {
      padding: ${({ theme }) => theme.spacing(2, 1)};
      display: flex;
      flex-direction: column;
      flex: 1;

      & > .MuiFormControl-root {
        margin: ${({ theme }) => theme.spacing(1, 0)};
      }
    }
  }
`;

const Actions = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: ${({ theme }) => theme.spacing(1)};

  button {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;

const RoleMenuItem = styled(MenuItem)`
  text-transform: capitalize;
`;

const StyledRoleChip = styled(RoleChip)`
  margin-top: -${({ theme }) => theme.spacing(1)};
  margin-bottom: -${({ theme }) => theme.spacing(1)};
`;

export interface UserFormProps {
  user?: UserDto;
  organizations: OrganizationDto[];
}

const UserForm: FC<UserFormProps> = ({ user, organizations }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { initialValues, validationSchema, onSubmit, isLoading } = useForm(user);
  const passwordRules = useStore((store) => store.config.passwordRules.validationRegexHint);

  return (
    <Root>
      <Typography variant="h5" ml={1}>
        {user === undefined ? t('pages.userDetails.titles.add') : t('pages.userDetails.titles.edit', user)}
      </Typography>
      <Formik<UserFormData> initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form>
          <section>
            <section>
              <FormikTextField disabled={isLoading} required name="email" label={t('pages.userDetails.fields.email')} />
              <FormikTextField
                disabled={isLoading}
                required
                name="firstName"
                label={t('pages.userDetails.fields.firstName')}
              />
              <FormikTextField
                disabled={isLoading}
                required
                name="lastName"
                label={t('pages.userDetails.fields.lastName')}
              />
              <FormikSelectField
                required
                multiple
                name="roles"
                label={t('pages.userDetails.fields.roles')}
                renderValue={(selected: unknown) =>
                  (selected as UserRole[]).map((value) => <StyledRoleChip key={value} role={value} size="medium" />)
                }
              >
                {ALL_USER_ROLES.map((role) => (
                  <RoleMenuItem key={role} value={role}>
                    {getRoleName(role)}
                  </RoleMenuItem>
                ))}
              </FormikSelectField>
              <FormikTextField
                disabled={isLoading}
                required
                name="currentWeek"
                type="number"
                label={t('pages.userDetails.fields.currentWeek')}
                helperText={t('pages.userDetails.other.currentWeekHint')}
              />
            </section>
            <section>
              <FormikSwitchField name="active" label={t('pages.userDetails.fields.active')} disabled={isLoading} />
              <FormikTextField
                disabled={isLoading}
                helperText={t('pages.userDetails.other.passwordHint', { passwordRules })}
                name="password"
                label={t('pages.userDetails.fields.password')}
                type="password"
              />
              <FormikTextField
                disabled={isLoading}
                helperText={t('pages.userDetails.other.passwordChangeHint')}
                name="confirmPassword"
                label={t('pages.userDetails.fields.confirmPassword')}
                type="password"
              />
            </section>
          </section>
          <section>
            <section>
              <FormikAutocompleteSelect<OrganizationDto, true, false, false>
                multiple
                fullWidth
                disabled={isLoading}
                options={organizations}
                name="organizations"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label={t('pages.userDetails.fields.organizations')} />}
              />
            </section>
          </section>
          <Actions>
            <Button onClick={() => navigate('/users')} disabled={isLoading}>
              {t('pages.userDetails.actions.cancel')}
            </Button>
            <LoadingButton disabled={isLoading} loading={isLoading} variant="contained" type="submit">
              {t('pages.userDetails.actions.save')}
            </LoadingButton>
          </Actions>
        </Form>
      </Formik>
    </Root>
  );
};
export default UserForm;
