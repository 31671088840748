import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import styled from 'styled-components';

import MuiAvatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import PasswordIcon from '@mui/icons-material/Password';

import FormikTextField from 'components/FormikTextField/FormikTextField';
import useStore from 'store';

import useForm from './hooks/useForm';
import Result from './components/Result/Result';

const Root = styled.div`
  margin: ${({ theme }) => theme.spacing(8, 4)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Avatar = styled(MuiAvatar)`
  margin: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

const SubmitButton = styled(LoadingButton)`
  margin: ${({ theme }) => theme.spacing(3, 0, 2)};
`;

interface ResetPasswordFormProps {
  token: string;
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ token }) => {
  const { t } = useTranslation();
  const passwordRules = useStore((store) => store.config.passwordRules.validationRegexHint);
  const { initialValues, validationSchema, onSubmit, isLoading, result } = useForm(token);

  return (
    <Root>
      <Avatar>
        <PasswordIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t('pages.forgotPassword.title')}
      </Typography>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form>
          <FormikTextField
            name="password"
            type="password"
            label={t('pages.forgotPassword.password')}
            fullWidth
            margin="normal"
            required
            disabled={isLoading}
            autoComplete="password"
            helperText={t('pages.userDetails.other.passwordHint', { passwordRules })}
          />
          <FormikTextField
            name="confirmPassword"
            type="password"
            label={t('pages.forgotPassword.confirmPassword')}
            fullWidth
            margin="normal"
            required
            disabled={isLoading}
            autoComplete="password"
          />
          {result !== undefined ? (
            <Result result={result} />
          ) : (
            <SubmitButton
              data-test-id="login-sign-in-button"
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
              loading={isLoading}
            >
              {t('pages.forgotPassword.changePassword')}
            </SubmitButton>
          )}
        </Form>
      </Formik>
    </Root>
  );
};

export default ResetPasswordForm;
