import { AccountDto, SummaryDashboardDto, TimesheetDto, UpsertTimesheetDto } from 'types';

import Client from '../../Client';

export enum Endpoints {
  ACCOUNTS_WITH_WORKLOCATIONS = '/dashboard/accounts-with-worklocations',
  WEEK_SUMMARY = '/dashboard/worklocation/{id}/week/{week}/summary',
  WEEK_DETAILS = '/dashboard/worklocation/{worklocationId}/week/{week}/details/{departmentId}',
  ACCEPT = '/dashboard/timesheet/accept',
  RELEASE = '/dashboard/timesheet/release',
  UPSERT = '/dashboard/timesheet/upsert',
}

/**
 * Fetch accounts with worklocations for dashboard dropdowns
 */
export const fetchAccountsWithWorklocations = async (): Promise<AccountDto[]> => {
  return (await Client.get(Endpoints.ACCOUNTS_WITH_WORKLOCATIONS)).data;
};

/**
 * Fetch week summary for selected worklocation
 *
 * @param worklocationId
 * @param week Week in YYYY-MM format (2022-51)
 */
export const fetchWeekSummary = async (worklocationId: number, week: string): Promise<SummaryDashboardDto> => {
  const url = Endpoints.WEEK_SUMMARY.replace('{id}', String(worklocationId)).replace('{week}', week);

  return (await Client.get(url)).data;
};

/**
 * Fetch summary for selected worklocation/week/department
 *
 * @param worklocationId
 * @param week Week in YYYY-MM format (2022-51)
 * @param departmentId
 */
export const fetchWeekDetails = async (
  worklocationId: number,
  week: string,
  departmentId: number | string,
): Promise<TimesheetDto[]> => {
  let url = Endpoints.WEEK_DETAILS.replace('{worklocationId}', String(worklocationId))
    .replace('{week}', week)
    .replace('{departmentId}', departmentId === 'all' ? '' : String(departmentId));

  if (url.endsWith('/')) {
    url = url.substring(0, url.length - 1);
  }

  return (await Client.get(url)).data;
};

/**
 * Accepts all timesheets passed by ID to this method
 *
 * @param timesheetsIds
 */
export const acceptTimesheets = async (...timesheetsIds: number[]): Promise<void> => {
  await Client.post(Endpoints.ACCEPT, timesheetsIds);
};

/**
 * Releases all timesheets passed by ID to this method
 *
 * @param timesheetsIds
 */
export const releaseTimesheets = async (...timesheetsIds: number[]): Promise<void> => {
  await Client.post(Endpoints.RELEASE, timesheetsIds);
};

/**
 * Upsert (Update or Edit) timesheet row
 *
 * @param upsertTimesheetRow
 */
export const upsertTimesheet = async (upsertTimesheetRow: UpsertTimesheetDto): Promise<void> => {
  await Client.post(Endpoints.UPSERT, upsertTimesheetRow);
};
