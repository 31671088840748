import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

import { useTranslation } from 'react-i18next';

export interface BreadcrumbsProps {}

const Root = styled(Paper)`
  flex: 1;
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

const Breadcrumbs: FC<BreadcrumbsProps> = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <MuiBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link underline="hover" color="inherit" component={RouterLink} to="/organizations">
          {t('pages.organizations.title')}
        </Link>
      </MuiBreadcrumbs>
    </Root>
  );
};

export default Breadcrumbs;
