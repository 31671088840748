import React, { FC, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';

import FormikAutocompleteSelect from 'components/FormikAutocompleteSelect/FormikAutocompleteSelect';
import { OrganizationGroupDto, Week } from 'types';
import useStore from 'store';
import ExportButton from './components/ExportButton/ExportButton';
import { useCheckedCustomerOverview } from 'context/CheckedCustomerOverview/CheckedCustomerOverview';
import { useFormikContext } from 'formik';
import { Form } from 'views/CustomerOverviewPage/types';

const Root = styled(Paper)`
  flex: 1;

  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-template-rows: 1fr;
  grid-gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

export interface CustomerOverviewFiltersProps {}

const CustomerOverviewFilters: FC<CustomerOverviewFiltersProps> = () => {
  const fetchData = useStore((store) => store.customerOverview.fetchData);
  const weeks = useStore((store) => store.customerOverview.weeks);
  const groups = useStore((store) => store.customerOverview.groups);
  const loading = useStore((store) => store.customerOverview.loading);
  const { checkedRows } = useCheckedCustomerOverview();
  const {
    values: { week },
  } = useFormikContext<Form>();

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <Root>
      <FormikAutocompleteSelect<Week, false, false, false>
        disablePortal
        loading={loading}
        options={weeks ?? []}
        name="week"
        renderInput={(params) => <TextField {...params} size="small" label="Week" />}
      />
      <FormikAutocompleteSelect<OrganizationGroupDto, false, false, false>
        disablePortal
        loading={loading}
        options={groups ?? []}
        name="group"
        getOptionLabel={(option) => `${option.name}`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params} size="small" label="Group" />}
      />
      {week && <ExportButton rows={checkedRows} week={week} />}
    </Root>
  );
};

export default CustomerOverviewFilters;
