import React, { ErrorInfo, FC } from 'react';

export interface BareErrorDisplayProps {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  onClose?: () => void;
}

const BareErrorDisplay: FC<BareErrorDisplayProps> = ({ error, errorInfo, onClose }) => (
  <div
    style={{
      border: '2px solid #ff4b4b',
      background: 'linear-gradient(30deg, #fff7f7, #ffe1e1)',
      padding: '24px',
      overflow: 'auto',
      position: 'relative',
    }}
  >
    {onClose && (
      <button
        style={{
          position: 'absolute',
          border: '1px solid #ef5350',
          color: '#ef5350',
          background: 'none',
          padding: '8px 16px',
          borderRadius: '3px',
          top: '8px',
          right: '8px',
          cursor: 'pointer',
        }}
        onClick={onClose}
      >
        Close
      </button>
    )}
    <div>
      <h2 style={{ color: '#ef5350', fontSize: '48px', margin: 0, fontWeight: 'normal' }}>Oops!</h2>
      <h4 style={{ color: '#ef5350', fontSize: '36px', margin: 0, fontWeight: 'normal' }}>Something went wrong!</h4>
    </div>
    <p style={{ marginTop: '8px', marginBottom: '4px', color: '#ef5350' }}>
      Please try again by navigating here or refreshing the whole page. If error persists, please contact administrator!
    </p>
    <details style={{ whiteSpace: 'pre-wrap' }}>
      <p style={{ color: '#ef5350' }}>{error && error.toString()}</p>
      <p style={{ color: '#ef5350' }}>
        <pre style={{ border: 0, overflow: 'auto' }}>{errorInfo && errorInfo.componentStack}</pre>
      </p>
    </details>
  </div>
);

export default BareErrorDisplay;
