import {BaseSchema} from 'yup';
import {DateTime} from 'luxon';
import Reference from 'yup/es/Reference';
import {Maybe} from 'yup/es/types';

export class LuxonDateTimeSchema extends BaseSchema<DateTime, any, DateTime> {
  static create() {
    return new LuxonDateTimeSchema();
  }

  constructor() {
    super();

    this.withMutation(() => {
      this.transform(function (value) {
        if (this.isType(value)) return value;

        throw new Error('Not a luxon.DateTime object!');
      });
    });
  }

  _typeCheck(value: unknown): value is NonNullable<DateTime> {
    return super._typeCheck(value) || (DateTime.isDateTime(value) && (value as DateTime).isValid);
  }

  // eslint-disable-next-line no-template-curly-in-string
  required(message = '${path} is required!') {
    return this.test({
      message,
      name: 'required',
      exclusive: true,
      test(value: Maybe<DateTime>) {
        return value !== null && value !== undefined && value.isValid;
      },
    });
  }

  // eslint-disable-next-line no-template-curly-in-string
  before(before: DateTime | Reference<DateTime>, message = '${path} must be before ${before}') {
    return this.test({
      message,
      name: 'before',
      exclusive: true,
      params: {before},
      test(value: Maybe<DateTime>) {
        return value! < this.resolve(before);
      },
    });
  }

  // eslint-disable-next-line no-template-curly-in-string
  after(after: DateTime | Reference<DateTime>, message = '${path} must be after ${after}') {
    return this.test({
      message,
      name: 'after',
      exclusive: true,
      params: {after},
      test(value: Maybe<DateTime>) {
        return value! > this.resolve(after);
      },
    });
  }

  asDurationLessThanClockTimeDifference(
    start: DateTime | Reference<DateTime>,
    end: DateTime | Reference<DateTime>,
    // eslint-disable-next-line no-template-curly-in-string
    message = '${path} when treated as duration must be less than difference between ${start} and ${end}',
  ) {
    return this.test({
      message,
      name: 'after',
      exclusive: true,
      params: {start, end},
      test(value: Maybe<DateTime>) {
        let startDateTime = this.resolve(start) as DateTime;
        let endDateTime = this.resolve(end) as DateTime;

        if (endDateTime === undefined || endDateTime === null || !endDateTime.isValid) {
          return false;
        }

        const startMinutes = startDateTime.get('minute') + startDateTime.get('hour') * 60;
        const endMinutes = endDateTime.get('minute') + endDateTime.get('hour') * 60;

        let startEndDifference = endMinutes - startMinutes;

        if (startEndDifference < 0) {
          startEndDifference = endMinutes + 24 * 60 - startMinutes;
        }

        const breakTime = value!.hour * 60 + value!.minute;

        return (startEndDifference > breakTime)
          || (
            startMinutes === 0
            && endMinutes === 0
            && breakTime === 0
          );
      },
    });
  }
}

export default LuxonDateTimeSchema.create;
