import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { GridRowParams } from '@mui/x-data-grid-pro';
import styled from 'styled-components';

import Layout from 'components/Layout/Layout';
import UsersTable from 'components/UsersTable/UsersTable';

import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import DataGridNoDataOverlay from '../../components/DataGridNoDataOverlay/DataGridNoDataOverlay';
import DataGridNoResultsOverlay from '../../components/DataGridNoResultsOverlay/DataGridNoResultsOverlay';
import DataGridToolbar from './components/DataGridToolbar/DataGridToolbar';
import { UserDto } from '../../types';

export interface UsersListPageProps {}

const Root = styled.div`
  display: block;
  min-height: 400px;
  // Viewport Height - all other elements of the page (approximate)
  height: calc(100vh - 48px - 40px - 64px - 20px - ${({ theme }) => theme.spacing(2)});
  flex: 1;

  .MuiDataGrid-actionsCell {
    grid-gap: 0;
  }
`;

const Container = styled.div`
  max-width: 1380px;
  align-self: start;
  flex: 1;

  display: grid;
  grid-template-rows: auto 1fr;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.spacing(2)};
`;

const UsersListPage: FC<UsersListPageProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getActions = useCallback(
    (params: GridRowParams<UserDto>): JSX.Element[] => {
      return [<Button onClick={() => navigate(`/users/${params.row.id}`)}>{t('pages.users.actions.details')}</Button>];
    },
    [t, navigate],
  );

  return (
    <Layout>
      <Container>
        <Breadcrumbs />
        <Root>
          <UsersTable
            getActions={getActions}
            initialState={{
              sorting: {
                sortModel: [{field: 'lastName', sort: 'asc'}]
              }
            }}
            components={{
              Toolbar: DataGridToolbar,
              NoRowsOverlay: DataGridNoDataOverlay,
              NoResultsOverlay: DataGridNoResultsOverlay,
            }}
          />
        </Root>
      </Container>
    </Layout>
  );
};

export default UsersListPage;
