import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Fade, IconButton, Popper, Typography } from "@mui/material";

export default function useClosablePopper() {
    const [open, setOpen] = React.useState(false);
    const [anchorElement, setAnchorElement] = React.useState<any>(null);
    const [title, setTitle] = React.useState<string>('');
    const [description, setDescription] = React.useState<string>('');
    const [onClickClose, setOnClickClose] = React.useState<() => void>();
  
    const handleTooltipClose = () => {
      setOpen(false);
      setAnchorElement(null);
      setTitle('');
      setDescription('');
      if (onClickClose) {
        onClickClose();
      }
    };
  
    const handleTooltipOpen = (anchorElementNew:any, newTitle:string, newDescription:string, onCloseClick?: () => void) => {
      setOpen(true);
      setAnchorElement(anchorElementNew);
      setTitle(newTitle);
      setDescription(newDescription);
      setOnClickClose(onCloseClick)
    };
  
    const ClosablePopper = <Popper id={'1'} open={open} anchorEl={anchorElement} transition placement="top-start">
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
            <Box sx={{ border: 1, p: 2, bgcolor: 'background.paper', position: 'relative', borderRadius: '8px'}}>
                <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                }}
                >
                <Typography variant="h6" component="span">
                    {title}
                </Typography>
                <IconButton
                    onClick={handleTooltipClose}
                >
                    <CloseIcon sx={{ color: 'red', cursor: 'pointer' }} />
                </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" component="span" sx={{ marginRight: 1 }}>
                    {description}
                </Typography>
                </Box>
            </Box>
        </Fade>
      )}
    </Popper>;
  
    return {
        ClosablePopper,
        handleTooltipOpen,
    };
}
