import { GridColDef, GridRowParams, GridActionsColDef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { UserDto, UserRole } from 'types';
import RoleChip from '../../RoleChip/RoleChip';

const useColumns = (getActions?: (params: GridRowParams<UserDto>) => JSX.Element[]): GridColDef<UserDto>[] => {
  const { t } = useTranslation();

  return useMemo(() => {
    const columns: (GridColDef<UserDto> | GridActionsColDef<UserDto>)[] = [
      {
        field: 'firstName',
        flex: 1,
        headerName: t('pages.users.columns.firstName'),
      },
      {
        field: 'lastName',
        flex: 1,
        headerName: t('pages.users.columns.lastName'),
      },
      {
        field: 'email',
        flex: 2,
        headerName: t('pages.users.columns.email'),
      },
      {
        field: 'organizations',
        flex: 0.5,
        headerName: t('pages.users.columns.organizations'),
        valueGetter: (params) => params.row.organizationsIds?.length,
      },
      {
        field: 'roles',
        flex: 2,
        headerName: t('pages.users.columns.roles'),
        renderCell: (params) => params.row.roles?.map((role) => <RoleChip role={role as UserRole} key={role} />),
      },
      {
        field: 'active',
        flex: 1,
        headerName: t('pages.users.columns.active'),
        type: 'boolean'
      },
    ];

    if (getActions) {
      columns.push({
        field: 'actions',
        type: 'actions',
        sortable: false,
        flex: 2,
        headerName: t('pages.users.columns.actions'),
        getActions,
      });
    }
    return columns;
  }, [t, getActions]);
};
export default useColumns;
