import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { Api } from 'api';
import Layout from 'components/Layout/Layout';
import useUsersData from 'components/UsersTable/hooks/useUsersData';

import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import OrganizationForm from './components/OrganizationForm/OrganizationForm';
import { FETCH_ORGANIZATION_DETAILS_QUERY, RouteParams } from './types';
import useOrganizationsGroupsData from 'components/OrganizationsGroupsTable/hooks/useOrganizationsGroupsData';

const Container = styled.div`
  max-width: 1380px;
  align-self: start;
  flex: 1;

  display: grid;
  grid-template-rows: auto 1fr;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.spacing(2)};
`;

export interface OrganizationDetailsPageProps {}

const OrganizationDetailsPage: FC<OrganizationDetailsPageProps> = () => {
  const { id } = useParams<RouteParams>();
  const { data: organization, isLoading: isOrganizationLoading } = useQuery(
    [FETCH_ORGANIZATION_DETAILS_QUERY, id],
    () => {
      if (id === 'new') {
        return Promise.resolve(undefined);
      } else {
        return Api.organization.fetchOrganizationDetails(id as string);
      }
    },
  );
  const { data: users = [], isLoading: isUsersListLoading } = useUsersData();
  const { data: groups = [], isLoading: isGroupDataListLoading } = useOrganizationsGroupsData();
  return (
    <Layout>
      <Container>
        <Breadcrumbs organization={organization} loading={isOrganizationLoading} />
        {!isOrganizationLoading && !isUsersListLoading && !isGroupDataListLoading && (
          <OrganizationForm organization={organization} users={users} groups={groups} />
        )}
      </Container>
    </Layout>
  );
};

export default OrganizationDetailsPage;
