import { GridInputRowSelectionModel, GridRowSelectionModel, GridValidRowModel } from '@mui/x-data-grid-pro';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

interface DataGridContextProps<R extends GridValidRowModel, Extra = unknown> {
  data: readonly R[];
  extra: Extra;
  selectionModel?: GridRowSelectionModel;
  onSelectionModelChange: (selectionModel: GridRowSelectionModel) => void;
}

export const DataGridContext = createContext<DataGridContextProps<{}>>({
  data: [],
  extra: undefined,
  selectionModel: undefined,
  onSelectionModelChange: () => null,
});

export const useDataGridContext = <R extends GridValidRowModel, Extra = unknown>(): DataGridContextProps<R, Extra> =>
  useContext(DataGridContext) as DataGridContextProps<R, Extra>;

export const useDataGridState = <R extends GridValidRowModel, Extra = unknown>(
  data: readonly R[],
  extra: Extra,
): DataGridContextProps<R, Extra> => {
  const [selectionModel, setSelectedRows] = useState<GridRowSelectionModel>([]);

  const onSelectionModelChange = useCallback(
    (newSelectionModel: GridInputRowSelectionModel) => {
      if (Array.isArray(newSelectionModel)) {
        setSelectedRows(newSelectionModel);
      } else {
        setSelectedRows([newSelectionModel]);
      }
    },
    [setSelectedRows],
  );

  return useMemo(
    () => ({
      data,
      extra,
      selectionModel,
      onSelectionModelChange,
    }),
    [extra, data, selectionModel, onSelectionModelChange],
  );
};
