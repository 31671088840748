import { getSliceCreator } from 'store/utils/utils';
import { PasswordRulesDto, VersionInfoDto } from 'types';
import { Api } from 'api';

interface Slice {
  passwordRules: PasswordRulesDto;
  versionInfo: VersionInfoDto;
  fetchConfig: () => Promise<void>;
}

export interface ConfigSlice {
  config: Slice;
}

const createConfigSlice = getSliceCreator<Slice>('config', (set) => ({
  passwordRules: {
    validationRegexHint: '',
    validationRegex: '[a-z].*',
  },
  versionInfo: {
    version: '?',
    commitRef: '?',
    buildDate: '?',
  },
  fetchConfig: async () => {
    const [passwordRules, versionInfo] = await Promise.all([
      Api.auth.fetchPasswordRules(),
      Api.auth.fetchVersionInfo(),
    ]);

    set({ passwordRules, versionInfo });
  },
}));

export default createConfigSlice;
