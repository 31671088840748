import React, { FC, ReactNode } from 'react';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';

export interface SnackbarProviderProps {
  children: ReactNode;
}

const SnackbarProvider: FC<SnackbarProviderProps> = ({ children }) => (
  <NotistackSnackbarProvider
    maxSnack={3}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
  >
    {children}
  </NotistackSnackbarProvider>
);

export default SnackbarProvider;
