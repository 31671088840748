import { AxiosInstance, AxiosResponse } from 'axios';

const getFilename = (response: AxiosResponse<unknown>, fallback: string): string => {
  const groups = /.*filename="?([^"]+)"?/.exec(response.headers['content-disposition']);

  if (groups !== null && groups.length >= 1) {
    return groups[1];
  }

  return fallback;
};

const getDownloader =
  (client: AxiosInstance): AxiosInstance['download'] =>
  async (url, config) => {
    const response = await client.request({
      url,
      ...config,
      responseType: 'blob',
    });
    const filename = getFilename(response, config.filename ?? 'export.csv');

    const blobUrl = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(blobUrl);
  };

export default getDownloader;
