import axios from 'axios';

import { requestInterceptor, responseErrorInterceptor, responseInterceptor } from './helpers/interceptors';
import getDownloader from './helpers/download';

const Client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

Client.interceptors.request.use(requestInterceptor);
Client.interceptors.response.use(responseInterceptor, responseErrorInterceptor);
Client.download = getDownloader(Client);

export default Client;
