import {TFunction} from 'react-i18next';
import {AxiosError} from 'axios';

import TranslatedError from './TranslatedError';

class GeneralApiError<T, D> extends TranslatedError {
  axiosError: AxiosError<T, D>;

  constructor(axiosError: AxiosError<T, D>, message: string = 'api.errors.general') {
    super(message);

    this.axiosError = axiosError;
  }

  getTranslatedMessage = (t: TFunction) => t(this.message);

  getAxiosError = () => this.axiosError;

  static wrapIfAxios = (error: any) => {
    if (error instanceof AxiosError) {
      return new GeneralApiError(error);
    }

    return error;
  };
}

export default GeneralApiError;
