import React, { FC, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import useStore from 'store';

import SlideUpTransition from '../../../../../SlideUpTransition/SlideUpTransition';
import ChangePasswordOverlay from '../../../../../ChangePasswordOverlay/ChangePasswordOverlay';
import RoleChip from '../../../../../RoleChip/RoleChip';
import { UserRole } from '../../../../../../types';
import { Chip } from '@mui/material';

export interface UserDetailsDialogProps {
  open: boolean;
  onClose: () => void;
}

const StyledDialogContentText = styled(DialogContentText)`
  display: flex;
  flex-direction: row;

  & > div {
    min-width: 150px;

    &:not(:first-child) {
      margin-left: ${({ theme }) => theme.spacing(4)};
    }

    h6 {
      margin-top: ${({ theme }) => theme.spacing(2)};
    }
  }
`;

const StyledChip = styled(Chip)`
  margin-inline: ${({ theme }) => theme.spacing(0.25)};
`;

const UserDetailsDialog: FC<UserDetailsDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const user = useStore((store) => store.user.user);
  const [changePasswordOpen, setChangePasswordOpen] = useState<boolean>(false);
  const id = useId();

  return (
    <Dialog open={open} TransitionComponent={SlideUpTransition} keepMounted onClose={onClose} aria-describedby={id}>
      <DialogTitle id={id}>{t('pages.currentUser.title')}</DialogTitle>
      <DialogContent>
        <StyledDialogContentText>
          <div>
            <Typography variant="subtitle2">{t('pages.currentUser.firstName')}</Typography>
            <Typography> {user?.firstName}</Typography>
            <Typography variant="subtitle2">{t('pages.currentUser.lastName')}</Typography>
            <Typography> {user?.lastName}</Typography>
            <Typography variant="subtitle2">{t('pages.currentUser.email')}</Typography>
            <Typography> {user?.email}</Typography>
          </div>
          <div>
            <Typography variant="subtitle2">{t('pages.currentUser.roles')}</Typography>
            <Typography>
              {user?.roles?.map((role) => (
                <RoleChip key={role} size="medium" role={role as UserRole} />
              ))}
            </Typography>
            <Typography variant="subtitle2">{t('pages.currentUser.organizations')}</Typography>
            <Typography>
              {user?.organizations?.map((organization) => (
                <StyledChip key={organization.id} size="medium" label={organization.name} />
              ))}
            </Typography>
          </div>
        </StyledDialogContentText>
        <ChangePasswordOverlay
          open={changePasswordOpen}
          onClose={() => setChangePasswordOpen(false)}
          AdditionalActions={({ loading, onClose: innerOnClose }) => (
            <Button disabled={loading} onClick={innerOnClose}>
              {t('common.close')}
            </Button>
          )}
          showExpiredRationale={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setChangePasswordOpen(true)}>{t('pages.changePassword.changePassword')}</Button>
        <Button onClick={onClose}>{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserDetailsDialog;
