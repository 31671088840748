import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import useDateNavigation from './hooks/useDateNavigation';

const Root = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(1, 2)};
  display: flex;
  justify-content: space-around;

  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export interface PaginationProps {}

const Pagination: FC<PaginationProps> = () => {
  const { t } = useTranslation();
  const { goToPreviousWeek, setCurrentWeek, goToNextWeek } = useDateNavigation();

  return (
    <Root>
      <div>
        <Button onClick={goToPreviousWeek} startIcon={<KeyboardArrowLeftIcon />}>
          {t('pages.dashboard.weeks.previous')}
        </Button>
        <Button onClick={setCurrentWeek}>{t('pages.dashboard.weeks.current')}</Button>
        <Button onClick={goToNextWeek} endIcon={<KeyboardArrowRightIcon />}>
          {t('pages.dashboard.weeks.next')}
        </Button>
      </div>
    </Root>
  );
};

export default Pagination;
