import React, { FC } from 'react';
import styled from 'styled-components';

import Layout from 'components/Layout/Layout';

import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import WeekTable from './components/WeekTable/WeekTable';
import TimesheetEdit from './components/TimsheetEdit/TimesheetEdit';

export interface DepartmentDetailsPageProps {}

const Container = styled.div`
  max-width: 100%;
  align-self: start;

  flex: 1;
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.spacing(2)};
`;

const DepartmentDetailsPage: FC<DepartmentDetailsPageProps> = () => {
  return (
    <Layout>
      <TimesheetEdit.ContextProvider>
        <Container>
          <Breadcrumbs />
          <WeekTable />
          <TimesheetEdit />
        </Container>
      </TimesheetEdit.ContextProvider>
    </Layout>
  );
};

export default DepartmentDetailsPage;
