import { useFormikContext } from 'formik';
import { useQuery } from 'react-query';

import { SummaryDashboardDto } from 'types';
import { Api } from 'api';

import { Form } from '../types';

export const FETCH_DASHBOARD_SUMMARY_KEY = 'dashboardSummary';

const EMPTY_DATA: SummaryDashboardDto = {
  summary: {
    accepted: false,
    week: '',
    plannedHours: '0:00',
    confirmedHours: '0:00',
  },
  departments: [],
};

const useFetchData = () => {
  const {
    values: { worklocation, week },
  } = useFormikContext<Form>();

  const { isLoading, data = EMPTY_DATA } = useQuery<SummaryDashboardDto>(
    [FETCH_DASHBOARD_SUMMARY_KEY, worklocation?.id, week],
    async () => {
      if (worklocation?.id === undefined) {
        return EMPTY_DATA;
      }

      return Api.dashboard.fetchWeekSummary(worklocation.id, week);
    },
  );

  return {
    data,
    isLoading,
  } as const;
};

export default useFetchData;
