import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ClearIcon from '@mui/icons-material/Clear';
import LoadingButton from '@mui/lab/LoadingButton';

import { useDataGridContext } from 'components/ControlledDataGrid/context/DataGridContext/DataGridContext';
import { TimesheetDto } from 'types';

import useAcceptReleaseAction from '../../../../../../../../hooks/useAcceptReleaseAction';

export interface AcceptAllSelectedButtonProps {}

const ReleaseAllSelectedButton: FC<AcceptAllSelectedButtonProps> = () => {
  const { t } = useTranslation();
  const { data, selectionModel = [] } = useDataGridContext<TimesheetDto>();

  const selectionContainsReleased = useMemo(
    () => data.some((entry) => selectionModel.includes(String(entry.id)) && !entry.accepted),
    [data, selectionModel],
  );

  const { isLoading, mutate } = useAcceptReleaseAction();

  return (
    <LoadingButton
      startIcon={<ClearIcon />}
      loadingPosition="start"
      loading={isLoading}
      disabled={selectionContainsReleased || isLoading || selectionModel.length === 0}
      onClick={() => mutate([false, ...selectionModel.map((e) => Number(e))])}
    >
      {t('pages.weekDetails.actions.releaseSelected')}
    </LoadingButton>
  );
};

export default ReleaseAllSelectedButton;
