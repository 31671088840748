import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import { Api } from 'api';

import { RouteParams } from '../../../../../../../types';

const useTableExport = () => {
  const { week, worklocation, department } = useParams<RouteParams>();

  if (week === undefined || worklocation === undefined || department === undefined) {
    throw new Error('Missing week!');
  }

  const { isLoading, mutate } = useMutation(['weekDetailsExport', worklocation, week, department], async () =>
    Api.exports.downloadWeekDetails(Number(worklocation), week, department),
  );

  return { isLoading, exportData: mutate as () => void };
};

export default useTableExport;
