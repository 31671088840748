import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';

import DashboardPage from './DashboardPage/DashboardPage';
import LoginPage from './LoginPage/LoginPage';
import ForgotPasswordPage from './ForgotPasswordPage/ForgotPasswordPage';
import DepartmentDetailsPage from './DepartmentDetailsPage/DepartmentDetailsPage';
import UsersListPage from './UsersListPage/UsersListPage';
import UserDetailsPage from './UserDetailsPage/UserDetailsPage';
import OrganizationsListPage from './OrganizationsListPage/OrganizationsListPage';
import OrganizationDetailsPage from './OrganizationDetailsPage/OrganizationDetailsPage';
import { UserRole } from '../types';
import CustomerOverviewPage from './CustomerOverviewPage/CustomerOverviewPage';

const Routing = () => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade-from-top" timeout={300}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password/:token" element={<ForgotPasswordPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route
            path="/dashboard/:week/worklocation/:worklocation/department/:department"
            element={
              <ProtectedRoute>
                <DepartmentDetailsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customer-overview"
            element={
              <ProtectedRoute roles={[UserRole.ADMIN, UserRole.OTTO]}>
                <CustomerOverviewPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customer-overview/:week"
            element={
              <ProtectedRoute roles={[UserRole.ADMIN, UserRole.OTTO]}>
                <CustomerOverviewPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/:week"
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/:week/account/:accountId/worklocation/:worklocationId"
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users/:id"
            element={
              <ProtectedRoute roles={[UserRole.ADMIN]}>
                <UserDetailsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute roles={[UserRole.ADMIN]}>
                <UsersListPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/organizations/:id"
            element={
              <ProtectedRoute roles={[UserRole.ADMIN]}>
                <OrganizationDetailsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/organizations"
            element={
              <ProtectedRoute roles={[UserRole.ADMIN]}>
                <OrganizationsListPage />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate replace to="/dashboard" />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default Routing;
