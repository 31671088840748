import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import styled from 'styled-components';

import MuiAvatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import PasswordIcon from '@mui/icons-material/Password';

import FormikTextField from 'components/FormikTextField/FormikTextField';

import useForm from './hooks/useForm';

const Root = styled.div`
  margin: ${({ theme }) => theme.spacing(8, 4)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Avatar = styled(MuiAvatar)`
  margin: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

const SubmitButton = styled(LoadingButton)`
  margin: ${({ theme }) => theme.spacing(3, 0, 2)};
`;

const SuccessMessage = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.success.main};
`;

const SendPasswordReminderForm = () => {
  const { t } = useTranslation();
  const { initialValues, validationSchema, onSubmit, isLoading, result } = useForm();

  return (
    <Root>
      <Avatar>
        <PasswordIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t('pages.forgotPassword.title')}
      </Typography>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form>
          <FormikTextField
            name="email"
            type="email"
            label={t('pages.forgotPassword.email')}
            fullWidth
            margin="normal"
            required
            disabled={isLoading}
            autoComplete="email"
          />
          {result ? (
            <SuccessMessage variant="body2" textAlign="center">
              {t('pages.forgotPassword.emailSent')}
            </SuccessMessage>
          ) : (
            <SubmitButton
              data-test-id="login-sign-in-button"
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
              loading={isLoading}
            >
              {t('pages.forgotPassword.sendReminder')}
            </SubmitButton>
          )}
        </Form>
      </Formik>
    </Root>
  );
};

export default SendPasswordReminderForm;
