import React, { FC } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import styled from 'styled-components';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';

import { useTranslation } from 'react-i18next';
import { OrganizationDto } from 'types';

import { RouteParams } from '../../types';

export interface BreadcrumbsProps {
  organization?: OrganizationDto;
  loading?: boolean;
}

const Root = styled(Paper)`
  flex: 1;
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

const Breadcrumbs: FC<BreadcrumbsProps> = ({ organization, loading }) => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();

  return (
    <Root>
      <MuiBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link underline="hover" color="inherit" component={RouterLink} to="/organizations">
          {t('pages.organizations.title')}
        </Link>
        <Link underline="hover" color="inherit" component={RouterLink} to={`/organizations/${id}`}>
          {loading && <CircularProgress size={12} />}
          {!loading &&
            (organization === undefined
              ? t('pages.organizationDetails.other.newOrganization')
              : `${organization.name}`)}
        </Link>
      </MuiBreadcrumbs>
    </Root>
  );
};

export default Breadcrumbs;
