import {ChangePasswordDto, LoginResponseDto, PasswordRulesDto, UserDto, VersionInfoDto} from 'types';
import store from 'store';

import Client from '../../Client';
import { GeneralApiError } from '../../errors';
import InvalidCredentialsError from '../../errors/InvalidCredentialsError';
import DisabledUserError from '../../errors/DisabledUserError';

export enum Endpoints {
  LOGIN = '/login',
  LOGOUT = '/logout',
  REFRESH_TOKEN = '/token/refresh',
  CURRENT_USER = '/auth/current-user',
  CHANGE_PASSWORD = '/auth/change-password',
  PASSWORD_RULES = '/auth/password-rules',
  VERSION_INFO = '/auth/version-info',
  SEND_PASSWORD_REMINDER = '/auth/forgot-password/',
  RESET_PASSWORD = '/auth/forgot-password/{token}',
}

export const login = async (username: string, password: string): Promise<LoginResponseDto> => {
  try {
    const response = await Client.post<LoginResponseDto>(Endpoints.LOGIN, { username, password }, { ignoreRefresh: true });
    return response.data;
  } catch (error) {
    if (error instanceof GeneralApiError && error.getAxiosError()?.response?.status === 401) {
      if (error.getAxiosError().response?.data.message.includes('[4010]')) {
        throw new DisabledUserError();
      }

      throw new InvalidCredentialsError();
    }

    throw error;
  }
};

export interface RefreshTokenResponse {
  token: string;
  refreshToken: string;
}

export const refreshToken = async (): Promise<RefreshTokenResponse> => {
  if (store.getState().user.refreshToken === undefined) {
    throw new Error('Missing token!');
  }

  const data = (
    await Client.post<RefreshTokenResponse>(
      Endpoints.REFRESH_TOKEN,
      {
        refreshToken: store.getState().user.refreshToken,
      },
      { skipJWT: true },
    )
  ).data;

  store.getState().user.setTokens(data.token, data.refreshToken);

  return data;
};

export const fetchCurrentUser = async (): Promise<UserDto> => {
  return (await Client.get<UserDto>(Endpoints.CURRENT_USER)).data;
};

export const logout = async (): Promise<void> => {
  await Client.post(Endpoints.LOGOUT, {}, { ignoreRefresh: true });
};

export const fetchPasswordRules = async (): Promise<PasswordRulesDto> => {
  return (await Client.get(Endpoints.PASSWORD_RULES)).data;
};

export const fetchVersionInfo = async (): Promise<VersionInfoDto> => {
  return (await Client.get(Endpoints.VERSION_INFO)).data;
};

export const sendPasswordReminder = async (email: string): Promise<boolean> => {
  const response = await Client.post(Endpoints.SEND_PASSWORD_REMINDER, { email });

  return response.status < 400;
};

export const resetPassword = async (token: string, password: string): Promise<boolean> => {
  try {
    const response = await Client.post(Endpoints.RESET_PASSWORD.replace('{token}', token), { password });

    return response.status < 400;
  } catch (e) {
    return false;
  }
};

export const changePassword = async (params: ChangePasswordDto): Promise<number> => {
  try {
    const response = await Client.post(Endpoints.CHANGE_PASSWORD, params);

    return response.status;
  } catch (e) {
    if (e instanceof GeneralApiError) {
      return e.getAxiosError().response?.status ?? 500;
    }

    return 500;
  }
};
