import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';

import { TimesheetDto } from 'types';
import useIsOttoUser from 'hooks/useIsOttoUser/useIsOttoUser';

import AcceptReleaseAction from '../components/AcceptReleaseAction/AcceptReleaseAction';
import EditTimesheetRowAction from '../components/EditTimesheetRowAction/EditTimesheetRowAction';

const useColumns = (): GridColDef<TimesheetDto>[] => {
  const { t } = useTranslation();
  const isOttoUser = useIsOttoUser();

  return useMemo(
    () => [
      {
        field: 'date',
        type: 'date',
        width: 95,
        headerName: t('pages.weekDetails.table.columns.date'),
        valueGetter: (params) => {
          return params.row.date ? new Date((params.row.date)) : null;
        },
      },
      {
        field: 'employee.sapId',
        type: 'number',
        width: 80,
        headerName: t('pages.weekDetails.table.columns.employeeId'),
        valueGetter: (params) => params.row.employee?.sapId,
      },
      {
        field: 'employee.firstName',
        width: 150,
        headerName: t('pages.weekDetails.table.columns.firstName'),
        valueGetter: (params) => params.row.employee?.firstName,
      },
      {
        field: 'employee.lastName',
        width: 150,
        headerName: t('pages.weekDetails.table.columns.lastName'),
        valueGetter: (params) => params.row.employee?.lastName,
      },
      {
        field: 'plannedStartTime',
        align: 'right',
        headerAlign: 'right',
        width: 80,
        headerName: t('pages.weekDetails.table.columns.plannedStart'),
      },
      {
        field: 'plannedEndTime',
        align: 'right',
        headerAlign: 'right',
        width: 80,
        headerName: t('pages.weekDetails.table.columns.plannedEnd'),
      },
      {
        field: 'plannedBreakTime',
        align: 'right',
        headerAlign: 'right',
        width: 80,
        headerName: t('pages.weekDetails.table.columns.plannedBreak'),
      },
      {
        field: 'plannedDurationTime',
        align: 'right',
        headerAlign: 'right',
        width: 80,
        headerName: t('pages.weekDetails.table.columns.plannedDuration'),
      },
      {
        field: 'actualStartTime',
        align: 'right',
        width: 80,
        headerName: t('pages.weekDetails.table.columns.actualStart'),
      },
      {
        field: 'actualEndTime',
        align: 'right',
        headerAlign: 'right',
        width: 80,
        headerName: t('pages.weekDetails.table.columns.actualEnd'),
      },
      {
        field: 'actualBreakTime',
        align: 'right',
        headerAlign: 'right',
        width: 80,
        headerName: t('pages.weekDetails.table.columns.actualBreak'),
      },
      {
        field: 'actualDurationTime',
        align: 'right',
        headerAlign: 'right',
        width: 80,
        sortable: false,
        headerName: t('pages.weekDetails.table.columns.actualDuration'),
      },
      {
        field: 'accepted',
        type: 'boolean',
        align: 'center',
        width: 80,
        headerName: t('pages.weekDetails.table.columns.accepted'),
      },
      {
        field: 'actions',
        type: 'actions',
        sortable: false,
        width: 140,
        headerName: t('pages.weekDetails.table.columns.actions'),
        getActions: (params: GridRowParams<TimesheetDto>) => {
          const actions = [<AcceptReleaseAction gridRowParams={params} isOttoUser={isOttoUser} />];

          if (!isOttoUser) {
            actions.push(<EditTimesheetRowAction gridRowParams={params} />);
          }

          return actions;
        },
      },
    ],
    [t, isOttoUser],
  );
};

export default useColumns;
