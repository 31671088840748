import React, { FC, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';

export interface FormikTimeFieldProps
  extends Omit<TimePickerProps<DateTime, DateTime>, 'value' | 'onChange' | 'renderInput'> {
  name: string;
  id?: string;
  required?: boolean;
}

const FormikTimeField: FC<FormikTimeFieldProps> = ({ name, id, required, ...props }) => {
  const form = useFormikContext();

  const { error, touched } = form.getFieldMeta(name);
  const { value, onBlur, onChange } = form.getFieldProps(name);

  const handleChange = useCallback(
    (newValue: DateTime | null) => {
      onChange({ target: { value: newValue, name, id } });
    },
    [onChange, name, id],
  );

  const hasError = error !== undefined && touched;

  return (
    <TimePicker
      {...props}
      value={value}
      ampm={false}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          name={name}
          id={id}
          onBlur={onBlur}
          error={hasError}
          helperText={hasError ? error : ''}
        />
      )}
    />
  );
};

export default FormikTimeField;
