import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import useRowsExport from '../hooks/useRowsExport';
import { CustomerWeekOverviewDto } from 'types';
import { Tooltip } from '@mui/material';

const Button = styled(LoadingButton)`
  margin-left: ${({ theme }) => theme.spacing(2)};
` as typeof LoadingButton;

export interface ExportButtonProps {
    rows: CustomerWeekOverviewDto[];
    week: string;
}

/**
 * Export button for exporting the selected rows
 */
const ExportButton: FC<ExportButtonProps> = ({ rows, week }) => {
  const { t } = useTranslation();
  const { isLoading, exportData } = useRowsExport(rows, week);
  const allRowsAccepted = rows.every((row) => row.accepted);
  const buttonCheck = () => {
    if (isLoading) {
      return { buttonIsDisabled: true, tooltip: t('common.loading') };
    } else if (!allRowsAccepted) {
      return { buttonIsDisabled: true, tooltip: t('pages.customerOverview.exportMany.disabled.notAccepted') };
    } else if (rows.length === 0) {
      return { buttonIsDisabled: true, tooltip: t('pages.customerOverview.exportMany.disabled.empty') };
    }
    return { buttonIsDisabled: false, tooltip: t('common.export') };
  };

  const { buttonIsDisabled, tooltip } = buttonCheck();
  return (
    <Tooltip title={ tooltip }>
        <span>
            <Button
                variant="contained"
                component="label"
                disabled={isLoading || buttonIsDisabled}
                loading={isLoading}
                loadingPosition="start"
                startIcon={<FileDownloadIcon />}
                onClick={exportData}
            >
                {t('pages.weekDetails.actions.export')}
            </Button>
        </span>
    </Tooltip>
  );
};

export default ExportButton;
