import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';

import { addWeeksToWeek, subWeeksToWeek } from 'utils';

import { Form } from '../../../types';

const useDateNavigation = () => {
  const {
    setFieldValue,
    values: { week },
  } = useFormikContext<Form>();

  return useMemo(
    () => ({
      setCurrentWeek: () => {
        const newWeek = DateTime.now().toFormat('kkkk-WW');
        setFieldValue('week', newWeek);
        window.history.replaceState(null, '', `/dashboard/${week}`)
      },
      goToNextWeek: () => {
        const newWeek = addWeeksToWeek(week, 1);
        setFieldValue('week', newWeek);
        window.history.replaceState(null, '', `/dashboard/${newWeek}`)
      },
      goToPreviousWeek: () => {
        const newWeek = subWeeksToWeek(week, 1);
        setFieldValue('week', newWeek);
        window.history.replaceState(null, '', `/dashboard/${newWeek}`)
      },
    }),
    [setFieldValue, week],
  );
};

export default useDateNavigation;
