import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import NoDataIcon from './components/NoDataIcon/NoDataIcon';

export interface DataGridNoDataResultsOverlayProps {}

const StyledGridOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  & .ant-empty-img-1 {
    fill: #aeb8c2;
  }

  & .ant-empty-img-2 {
    fill: #f5f5f7;
  }

  & .ant-empty-img-3 {
    fill: #dce0e6;
  }

  & .ant-empty-img-4 {
    fill: #aeb8c2;
  }

  .MuiTypography-subtitle1 {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

const DataGridNoResultsOverlay: FC<DataGridNoDataResultsOverlayProps> = () => {
  const { t } = useTranslation();

  return (
    <StyledGridOverlay>
      <NoDataIcon />
      <Typography variant="subtitle1">{t('common.noResults')}</Typography>
    </StyledGridOverlay>
  );
};

export default DataGridNoResultsOverlay;
