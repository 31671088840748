import React, { ErrorInfo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

const Root = styled(Paper)`
  border: 2px solid #ff4b4b;
  background: linear-gradient(30deg, #fff7f7, #ffe1e1);
  padding: ${({ theme }) => theme.spacing(4)};
  height: min-content;
  position: relative;

  h2,
  h4,
  & > *:not(button) {
    color: ${({ theme }) => theme.palette.error.light};
  }

  .title {
    & > * {
      padding-right: ${({ theme }) => theme.spacing(1)};
    }
  }

  details {
    white-space: pre-wrap;

    pre {
      margin: 0;
      overflow: auto;
    }
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(2)};
  right: ${({ theme }) => theme.spacing(2)};
`;

export interface ErrorDisplayProps {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  onClose?: () => void;
}

const ErrorDisplay: FC<ErrorDisplayProps> = ({ error, errorInfo, onClose }) => {
  const { t } = useTranslation();

  return (
    <Root>
      {onClose && (
        <CloseButton onClick={onClose} variant="outlined" color="error">
          {t('errors.close')}
        </CloseButton>
      )}
      <div className="title">
        <Typography variant="h2">{t('errors.oops')}</Typography>
        <Typography variant="h4">{t('errors.somethingWentWrong')}</Typography>
      </div>
      <Typography mt={2} mb={1}>
        {t('errors.tryAgainAfterError')}
      </Typography>
      <details>
        <Typography>{error && error.toString()}</Typography>
        <Typography>
          <pre>{errorInfo && errorInfo.componentStack}</pre>
        </Typography>
      </details>
    </Root>
  );
};

export default ErrorDisplay;
