import React, { FC } from 'react';
import TableRow from '@mui/material/TableRow';

import PlaceholderCell, { PlaceholderCellProps } from './components/PlaceholderCell/PlaceholderCell';

export interface CellDefinition extends PlaceholderCellProps {}

export interface TableRowPlaceholderProps {
  cells: CellDefinition[];
}

const TableRowPlaceholder: FC<TableRowPlaceholderProps> = ({ cells }) => (
  <TableRow>
    {cells.map((cell, index) => (
      <PlaceholderCell key={String(index)} {...cell} />
    ))}
  </TableRow>
);

export default TableRowPlaceholder;
