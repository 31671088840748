import { OrganizationGroupDto } from 'types';

import Client from '../../Client';

export enum Endpoints {
  LIST = '/organization-group',
}

/**
 * Fetch organizations
 */
export const fetchOrganizationGroupsList = async (): Promise<OrganizationGroupDto[]> => {
  return (await Client.get(Endpoints.LIST)).data ?? [];
};

