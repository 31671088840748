import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import LogoutIcon from '@mui/icons-material/Logout';

export interface LogoutButtonProps {
  logout: () => void;
  loading: boolean;
}

const LogoutButton: FC<LogoutButtonProps> = ({ loading, logout }) => {
  const { t } = useTranslation();

  return (
    <MenuList>
      <MenuItem onClick={logout} disabled={loading}>
        <ListItemIcon />
        <ListItemText>{t('common.logout')}</ListItemText>
        <ListItemIcon>
          {loading ? <CircularProgress size={16} color="secondary" /> : <LogoutIcon fontSize="small" color="inherit" />}
        </ListItemIcon>
      </MenuItem>
    </MenuList>
  );
};

export default LogoutButton;
