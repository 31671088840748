import React, { createContext, useContext, useState, ReactNode } from 'react';
import { CustomerWeekOverviewDto } from 'types';

interface CheckedCustomerOverviewInterface {
  setChecked: (data: CustomerWeekOverviewDto[]) => void;
  isChecked: (data: CustomerWeekOverviewDto) => boolean;
  checkedRows: CustomerWeekOverviewDto[];
}

const CheckedCustomerOverview = createContext<CheckedCustomerOverviewInterface>({
  setChecked: () => null,
  isChecked: () => false,
  checkedRows: [],
});

/**
 * Provider for the CheckedCustomerOverview context
 * Holds the checked rows in the customer overview table
 */
export const CheckedCustomerOverviewProvider: React.FC<{children?: ReactNode | undefined}> = (props) => {
  const [checkedRows, setcheckedRows] = useState<CustomerWeekOverviewDto[]>([]);
  const setChecked = (data: CustomerWeekOverviewDto[]) => {
    setcheckedRows(data);
  }
  const isChecked = (data: CustomerWeekOverviewDto) => {
    return checkedRows.includes(data);
  }
  return <CheckedCustomerOverview.Provider value={{
    setChecked,
    isChecked,
    checkedRows,
  }}>{props.children}</CheckedCustomerOverview.Provider>;
}

/**
 * Hook to use the CheckedCustomerOverview context
 */
export const useCheckedCustomerOverview = () => useContext(CheckedCustomerOverview);
