import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import createUserSlice, { UserSlice } from './slices/user/user';
import createAccountsSlice, { AccountsSlice } from './slices/accounts/accounts';
import createConfigSlice, { ConfigSlice } from './slices/config/config';
import createCustomerOverviewSlice, { CustomerOverviewSlice } from './slices/customerOverview/customerOverview';

export type State = UserSlice & AccountsSlice & ConfigSlice & CustomerOverviewSlice;

const useStore = create<State>()(
  devtools(
    persist(
      (set, get) =>
        ({
          ...createUserSlice(set, get),
          ...createAccountsSlice(set, get),
          ...createConfigSlice(set, get),
          ...createCustomerOverviewSlice(set, get),
        } as State),
      {
        name: 'otto-cp-root-store',
        merge: (persistedState, currentState: State) => {
          const newState = { ...currentState };

          Object.getOwnPropertyNames(persistedState ?? {}).forEach((sliceName) => {
            const name = sliceName as keyof State;

            // @ts-ignore
            newState[name] = {
              ...(currentState[name] ?? {}),
              ...(persistedState as State)[name],
            };
          });
          return newState;
        },
      },
    ),
  ),
);

export type Store = typeof useStore;

export default useStore;
