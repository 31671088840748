import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { OrganizationDto, OrganizationGroupDto } from 'types';
import { Api, GeneralApiError } from 'api';
import { FETCH_USERS_QUERY_KEY } from 'components/UsersTable/hooks/useUsersData';
import { FETCH_ORGANIZATIONS_QUERY_KEY } from 'components/OrganizationsTable/hooks/useOrganizationsData';
import { useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik/dist/types';
import { FETCH_ORGANIZATION_DETAILS_QUERY } from '../../../types';

export interface OrganizationFormData {
  name: string;
  active: boolean;
  users: {
    id?: number;
  }[];
  group?: OrganizationGroupDto;
}

const useForm = (organization?: OrganizationDto, groups?: OrganizationGroupDto[]) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { isLoading, mutateAsync } = useMutation(['upsertOrganization'], Api.organization.upsertOrganization, {
    onSuccess: async ({ id }) => {
      await queryClient.invalidateQueries([FETCH_USERS_QUERY_KEY, FETCH_ORGANIZATIONS_QUERY_KEY]);
      await queryClient.refetchQueries([FETCH_ORGANIZATION_DETAILS_QUERY]);

      if (organization === undefined) {
        navigate(`/organizations/${id}`);
      }
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = useMemo(
    () => ({
      name: organization?.name ?? '',
      active: organization?.active ?? true,
      users: organization?.users ?? [],
      group: organization?.group ? groups?.find((value) => value.id === organization.group) : undefined,
    }),
    [organization, groups],
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('validation.required')),
        organizations: yup.array(),
      }),
    [t],
  );

  const onSubmit = useCallback(
    async (formValues: OrganizationFormData, helpers: FormikHelpers<OrganizationFormData>) => {
      try {
        await mutateAsync({
          ...formValues,
          id: organization?.id,
          usersIds: formValues.users.filter((user) => user.id !== undefined).map((user) => user.id as number),
          users: undefined,
          group: formValues.group?.id,
        });
        enqueueSnackbar(t('pages.organizationDetails.results.success', formValues), { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(t('pages.organizationDetails.results.fail', formValues), { variant: 'error' });

        if (e instanceof GeneralApiError && e.getAxiosError()?.response?.data?.code === 4001) {
          helpers.setFieldError('name', t('pages.organizationDetails.results.failAlreadyExists', formValues));
        }
      }
    },
    [mutateAsync, organization, t, enqueueSnackbar],
  );

  return {
    initialValues,
    validationSchema,
    onSubmit,
    isLoading,
  } as const;
};

export default useForm;
