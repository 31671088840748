import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import { DepartmentWeekSummaryDto } from 'types';

import { Form } from '../../../../types';
import { useMutation } from 'react-query';
import { Api } from '../../../../../../api';
import { Tooltip } from '@mui/material';

export interface RowProps {
  value: DepartmentWeekSummaryDto;
  summary?: boolean;
  noData?: boolean;
}


const Row = ({ value: department, noData, summary }: RowProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    values: { week, worklocation },
  } = useFormikContext<Form>();

  const accepted = useMemo(() => {
    if (department.plannedHours === '0:00' || department.plannedHours === undefined) {
      return '--';
    }

    return t(department.accepted ? 'pages.dashboard.yes' : 'pages.dashboard.no');
  }, [department, t]);

  const navigateToDetails = useCallback(() => {
    navigate(`/dashboard/${week}/worklocation/${worklocation?.id}/department/${department.departmentId ?? 'all'}`);
  }, [department, navigate, week, worklocation]);

  const { isLoading, mutate } = useMutation(
    ['weekDetailsExport', worklocation, week],
    async () => Api.exports.downloadWeekSummary(Number(worklocation?.id), week),
  );

  const buttonIsDisabled = isLoading || noData || !department.accepted;

  return (
    <TableRow>
      <TableCell>{department.departmentName ?? `Total @ ${department.week}`}</TableCell>
      <TableCell align="right">{department.plannedHours}</TableCell>
      <TableCell align="right">{department.confirmedHours}</TableCell>
      <TableCell align="right">{accepted}</TableCell>
      <TableCell align="right">
        <Button onClick={navigateToDetails} disabled={noData}>
          {t('common.open')}
        </Button>
        {summary && (
          <Tooltip title={buttonIsDisabled ? t('pages.dashboard.export.disabled') : t('common.export')}>
            <span>
              <LoadingButton
                onClick={() => mutate()}
                loading={isLoading}
                disabled={buttonIsDisabled}
              >
                {t('common.export')}
              </LoadingButton>
            </span>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;
