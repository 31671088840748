import React, { FC } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';

import Layout from 'components/Layout/Layout';

import AccountAndWorklocationSelection from './components/AccountAndWorklocationSelection/AccountAndWorklocationSelection';
import SummaryTable from './components/SummaryTable/SummaryTable';
import Pagination from './components/Pagination/Pagination';
import { useParams } from 'react-router-dom';
import ReportWithWeekBreadcrump from 'components/ReportWithWeekBreadcrump/BreadcrumbWithWeek';
import { Form as FormType } from './types';

const StyledForm = styled(Form)`
  max-width: 1380px;
  align-self: start;
  flex: 1;

  display: grid;
  grid-template-rows: auto auto 1fr auto;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.spacing(2)};
`;

export interface DashboardPageProps {}

const DashboardPage: FC<DashboardPageProps> = () => {
  const { week } = useParams<{ week?: string }>();

  return (
    <Layout>
      <Formik
        initialValues={{
          account: null,
          worklocation: null,
          week: week ?? null,
        }}
        onSubmit={() => {}}
      >
        <StyledForm>
          <ReportWithWeekBreadcrump<FormType> reportName={'Dashboard'} />
          <AccountAndWorklocationSelection />
          <SummaryTable />
          <Pagination />
        </StyledForm>
      </Formik>
    </Layout>
  );
};

export default DashboardPage;
