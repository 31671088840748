import React from 'react';
import { Autocomplete, AutocompleteProps } from 'formik-mui';
import { useFormikContext } from 'formik';

export interface FormikAutocompleteSelectProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'form' | 'field' | 'meta'> {
  name: string;
}

const FormikAutocompleteSelect = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  name,
  onFocus,
  onBlur,
  ...props
}: FormikAutocompleteSelectProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const form = useFormikContext();

  const meta = form.getFieldMeta(name);
  const field = form.getFieldProps(name);

  return (
    <Autocomplete<T, Multiple, DisableClearable, FreeSolo> {...props} form={form} field={field} meta={meta} />
  );
};

export default FormikAutocompleteSelect;
