import { UseTranslationResponse } from 'react-i18next';
import { GeneralApiError } from './index';

type MessageTransformer = (message: string) => Record<string, any>;

class KnownCodeError {
  static getMessage = (
    error: Error | undefined | null,
    prefix: string,
    i18n: UseTranslationResponse<never>['i18n'],
    messageTransformer: MessageTransformer = (message) => ({ message }),
  ) => {
    if (error === undefined || error === null) {
      return null;
    }

    if (!(error instanceof GeneralApiError)) {
      return i18n.t(`${prefix}.unknown`);
    }

    const { code, message } = error.getAxiosError().response?.data;

    if (i18n.exists(`${prefix}.${code}`)) {
      // @ts-ignore
      return i18n.t(`${prefix}.${code}`, messageTransformer(message));
    }

    return i18n.t(`${prefix}.unknown`);
  };
}

export default KnownCodeError;
