import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { CustomerWeekOverviewDto } from 'types';
import { useMutation, useQueryClient } from 'react-query';
import { Api } from '../../../../../../api';
import { FETCH_KEY } from 'views/CustomerOverviewPage/hooks/useFetchData';
import { Tooltip } from '@mui/material';

export interface ExportButtonProps {
  overview: CustomerWeekOverviewDto;
  week: string;
}

/**
 * Export button for exporting accepted row
 */
const ExportAccepted: FC<ExportButtonProps> = ({ overview, week }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(
    ['weekDetailsExport', overview, week],
    async () => {
      return Api.exports.downloadWeekSummary(Number(overview?.worklocationId), week)
        .finally(() => {
            setTimeout(() => {
              queryClient.invalidateQueries(FETCH_KEY);
            }, 200);
        });
    },
  );

  const buttonIsDisabled = isLoading || !overview.accepted;

  return (
    <Tooltip title={buttonIsDisabled ? t('pages.dashboard.export.disabled') : t('common.export')}>
        <span>
          <LoadingButton onClick={() => mutate()} loading={isLoading} disabled={buttonIsDisabled}>
            {t('common.export')}
          </LoadingButton>
        </span>
    </Tooltip>
  );
};

export default ExportAccepted;
