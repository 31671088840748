import React, { FC } from 'react';
import { Switch, SwitchProps } from 'formik-mui';
import { Field } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';

export interface FormikTextFieldProps extends Omit<SwitchProps, 'form' | 'field' | 'meta'> {
  name: string;
  label?: string;
}

const FormikSwitchField: FC<FormikTextFieldProps> = ({ name, label, ...props }) => {
  if (label) {
    return (
      <FormControlLabel control={<Field {...props} component={Switch} type="checkbox" name={name} />} label={label} />
    );
  } else {
    return <Field {...props} component={Switch} type="checkbox" name={name} />;
  }
};

export default FormikSwitchField;
