import React, { FC } from 'react';
import styled from 'styled-components';
import { Check, Close } from '@mui/icons-material/';
import { CustomerWeekOverviewDto } from 'types';
import useFetchData from '../../hooks/useFetchData';
import useColumns from 'views/CustomerOverviewPage/hooks/useColumns';
import ControlledDataGrid from 'components/ControlledDataGrid/ControlledDataGrid';
import DataGridNoDataOverlay from 'components/DataGridNoDataOverlay/DataGridNoDataOverlay';
import DataGridNoResultsOverlay from 'components/DataGridNoResultsOverlay/DataGridNoResultsOverlay';
import { useTranslation } from 'react-i18next';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useCheckedCustomerOverview } from 'context/CheckedCustomerOverview/CheckedCustomerOverview';

const Root = styled.div`
  display: block;
  min-height: 400px;
  // Viewport Height - all other elements of the page (approximate)
  height: calc(100vh - 48px - 40px - 64px - 20px - ${({ theme }) => theme.spacing(2)});
  flex: 1;

  .MuiDataGrid-actionsCell {
    grid-gap: 0;
  }
  .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
  }
`;

const extractKey = (department: CustomerWeekOverviewDto) => String(department.worklocationId);
const StyledDataGrid = styled(ControlledDataGrid)`
  border: none;
`;

const StyledBooleanCellTrueIcon = styled(Check)`
  color: green !important;
`;

const StyledBooleanCellFalseIcon = styled(Close)`
  color: red !important;
`;

export interface SummaryTableProps {
  week: string;
  loading?: boolean;
}

const SummaryGrid: FC<SummaryTableProps> = ({ week, loading = false }) => {
  const { data, isLoading } = useFetchData();
  const columns = useColumns(week);
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const { setChecked } = useCheckedCustomerOverview();

  return (
    <Root>
        <StyledDataGrid
          apiRef={apiRef}
          rows={data}
          columns={columns}
          checkboxSelection
          disableRowSelectionOnClick
          loading={loading || isLoading}
          getRowId={extractKey}
          onRowSelectionModelChange={(model) => {
            const rows = model.map(element => {
              return apiRef.current.getRow(element);
            });
            setChecked(rows);
          }}
          components={{
            NoRowsOverlay: DataGridNoDataOverlay,
            NoResultsOverlay: DataGridNoResultsOverlay,
            BooleanCellTrueIcon: StyledBooleanCellTrueIcon,
            BooleanCellFalseIcon: StyledBooleanCellFalseIcon,
          }}
          localeText={{
            booleanCellTrueLabel: t('pages.weekDetails.table.elements.accepted'),
            booleanCellFalseLabel: t('pages.weekDetails.table.elements.notAccepted'),
          }}
        />
    </Root>
  );
};

export default SummaryGrid;
