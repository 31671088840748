import React, { FC } from 'react';
import { GridRowParams } from '@mui/x-data-grid-pro';

import ControlledDataGrid, { ControlledDataGridProps } from '../ControlledDataGrid/ControlledDataGrid';
import useColumns from './hooks/useColumns';
import { UserDto } from '../../types';
import useUsersData from './hooks/useUsersData';

const getRowId = (row: UserDto) => `${row.id}`;

export interface UsersTableProps
  extends Omit<
    ControlledDataGridProps<void>,
    'columns' | 'rows' | 'isLoading' | 'disableColumnFilter' | 'disableColumnMenu'
  > {
  getActions?: (params: GridRowParams<UserDto>) => JSX.Element[];
}

const UsersTable: FC<UsersTableProps> = ({ getActions, ...props }) => {
  const columns = useColumns(getActions);
  const { data = [], isLoading, isFetching } = useUsersData();

  return (
    <ControlledDataGrid
      {...props}
      columns={columns}
      rows={data}
      loading={isLoading || isFetching}
      disableColumnFilter
      disableColumnMenu
      getRowId={getRowId}
    />
  );
};

export default UsersTable;
