import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { TimesheetDto } from 'types';
import { Api } from 'api';

import { RouteParams } from '../types';

export const FETCH_DEPARTMENT_DETAILS_KEY = 'weekDetails';

const useFetchData = () => {
  const { week, worklocation, department } = useParams<RouteParams>();

  if (week === undefined || worklocation === undefined || department === undefined) {
    throw new Error('Missing week!');
  }

  const { data = [], ...rest } = useQuery<TimesheetDto[]>(
    [FETCH_DEPARTMENT_DETAILS_KEY, worklocation, week, department],
    async () => Api.dashboard.fetchWeekDetails(Number(worklocation), week, department),
  );

  return {
    week,
    data,
    ...rest,
  };
};

export default useFetchData;
