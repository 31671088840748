import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import useStore from 'store';

import ChangePasswordOverlay from '../ChangePasswordOverlay/ChangePasswordOverlay';

export interface ForceChangePasswordOverlayProps {}

const ForceChangePasswordOverlay: FC<ForceChangePasswordOverlayProps> = () => {
  const shouldChangePassword = useStore((store) => store.user.shouldChangePassword);
  const isLoggedIn = useStore((store) => store.user.isLoggedIn);
  const logout = useStore((store) => store.user.logout);

  const { t } = useTranslation();

  const AdditionalActions = useMemo(
    () =>
      ({ loading }: { loading: boolean }) =>
        (
          <Button disabled={loading} onClick={logout}>
            {t('common.logout')}
          </Button>
        ),
    [t, logout],
  );

  return (
    <ChangePasswordOverlay
      title={t('pages.changePassword.expiredTitle')}
      open={isLoggedIn && shouldChangePassword}
      onClose={() => {}}
      AdditionalActions={AdditionalActions}
      showExpiredRationale
    />
  );
};

export default ForceChangePasswordOverlay;
