import {TFunction} from 'react-i18next';

abstract class TranslatedError extends Error {
  abstract getTranslatedMessage(t: TFunction): string;

  static getTranslatedMessage = (error: any, t: TFunction): string => {
    if (error instanceof TranslatedError) {
      return error.getTranslatedMessage(t);
    }

    return t('api.errors.general');
  };
}

export default TranslatedError;
