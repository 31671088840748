import React, { FC } from 'react';
import styled from 'styled-components';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { DepartmentWeekSummaryDto } from 'types';
import TableContent from 'components/TableContent/TableContent';
import { CellDefinition } from 'components/TableRowPlaceholder/TableRowPlaceholder';

import Row from './components/Row/Row';
import useFetchData from '../../hooks/useFetchData';

const Root = styled.div`
  flex: 1;
  flex-direction: column;

  & > * {
    width: 100%;
  }
`;

const CELLS: CellDefinition[] = [
  { width: 80, widthVariance: 20 },
  { width: 40, widthVariance: 20, float: 'right' },
  { width: 40, widthVariance: 20, float: 'right' },
  { width: 40, widthVariance: 20, float: 'right' },
  { width: 80, float: 'right' },
];

const extractKey = (department: DepartmentWeekSummaryDto) => String(department.departmentId);

export interface SummaryTableProps {}

const SummaryTable: FC<SummaryTableProps> = () => {
  const { data, isLoading } = useFetchData();

  return (
    <Root>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="Summary table">
          <TableHead>
            <TableRow>
              <TableCell>Department</TableCell>
              <TableCell align="right">Planned</TableCell>
              <TableCell align="right">Confirmed</TableCell>
              <TableCell align="right">Accepted</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableContent
              data={data.departments}
              isLoading={isLoading}
              cells={CELLS}
              Row={Row}
              extractKey={extractKey}
            />
          </TableBody>
          <TableHead>
            <Row value={data.summary} summary noData={isLoading || data.departments.length === 0} />
          </TableHead>
        </Table>
      </TableContainer>
    </Root>
  );
};

export default SummaryTable;
