import { UserDto } from 'types';

import Client from '../../Client';

export enum Endpoints {
  LIST = '/user',
  UPSERT = '/user',
  DETAILS = '/user/{userId}',
  DELETE = '/user/{userId}',
}

/**
 * Fetch users
 */
export const fetchUserList = async (): Promise<UserDto[]> => {
  return (await Client.get(Endpoints.LIST)).data ?? [];
};

/**
 * Upsert user to the database
 */
export const upsertUser = async (user: UserDto): Promise<UserDto> => {
  return (await Client.post(Endpoints.UPSERT, user)).data;
};

/**
 * Fetch user details
 *
 * @param id User ID
 */
export const fetchUserDetails = async (id: string | number): Promise<UserDto> => {
  return (await Client.get(Endpoints.DETAILS.replace('{userId}', `${id}`))).data;
};
