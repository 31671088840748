import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GridToolbarContainer, GridToolbarProps, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { useParams } from 'react-router-dom';

import useIsOttoUser from 'hooks/useIsOttoUser/useIsOttoUser';
import { useDataGridContext } from 'components/ControlledDataGrid/context/DataGridContext/DataGridContext';
import { TimesheetDto } from 'types';

import useTableExport from './hooks/useTableExport';
import useClearFilters from './hooks/useClearFilters';
import SelectAllNotAcceptedButton from './components/SelectAllNotAcceptedButton/SelectAllNotAcceptedButton';
import SelectAllAcceptedButton from './components/SelectAllAcceptedButton/SelectAllAcceptedButton';
import ReleaseAllSelectedButton from './components/ReleaseAllSelectedButton/ReleaseAllSelectedButton';
import AcceptAllSelectedButton from './components/AcceptAllSelectedButton/AcceptAllSelectedButton';
import { useTimesheetEditContext } from '../../../../../TimsheetEdit/context/TimesheetEditContext';
import { TimesheetsTableExtra } from '../../TimesheetsTable';
import { RouteParams } from '../../../../../../types';

const Actions = styled.div`
  display: flex;

  & > * {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;

const Root = styled(GridToolbarContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export interface DataGridToolbarProps extends GridToolbarProps {}

const DataGridToolbar: FC<DataGridToolbarProps> = () => {
  const { t } = useTranslation();
  const isOttoUser = useIsOttoUser();
  const { department } = useParams<RouteParams>();

  const ref = useRef<HTMLInputElement>();
  const clearFilters = useClearFilters(ref);

  const {
    extra: { weekDay },
  } = useDataGridContext<TimesheetDto, TimesheetsTableExtra>();
  const { isLoading, exportData } = useTableExport();
  const { openDialog } = useTimesheetEditContext();

  const SelectAllRelevantButton = isOttoUser ? SelectAllAcceptedButton : SelectAllNotAcceptedButton;
  const AcceptReleaseButton = isOttoUser ? ReleaseAllSelectedButton : AcceptAllSelectedButton;

  return (
    <Root>
      <GridToolbarQuickFilter
        debounceMs={250}
        placeholder={t('pages.weekDetails.table.filter.filter')}
        aria-label={t('pages.weekDetails.table.filter.filter')}
        variant="outlined"
        size="small"
        inputRef={ref}
      />
      <Actions>
        {!isOttoUser && weekDay !== undefined && department !== 'all' && (
          <Button onClick={() => openDialog(weekDay)} startIcon={<PersonAddAlt1Icon />}>
            {t('pages.weekDetails.actions.addEmployee')}
          </Button>
        )}
        <SelectAllRelevantButton clearFilters={clearFilters} />
        <AcceptReleaseButton />
        <LoadingButton
          disabled={isLoading}
          loading={isLoading}
          loadingPosition="start"
          startIcon={<FileDownloadIcon />}
          onClick={exportData}
        >
          {t('pages.weekDetails.actions.export')}
        </LoadingButton>
      </Actions>
    </Root>
  );
};

export default DataGridToolbar;
