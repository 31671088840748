import React, { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { TimesheetDto } from 'types';
import { DateTime } from 'luxon';

interface TimesheetEditContextState {
  timesheet?: TimesheetDto;
  open: boolean;
  date?: DateTime;
  openedCount: number;
}

interface TimesheetEditContextProps extends TimesheetEditContextState {
  openDialog: (date: DateTime, timesheet?: TimesheetDto) => void;
  closeDialog: () => void;
}

const TimesheetEditContext = createContext<TimesheetEditContextProps>({
  timesheet: undefined,
  open: false,
  date: undefined,
  openedCount: 0,
  openDialog: () => {},
  closeDialog: () => {},
});

export const useTimesheetEditContext = () => useContext(TimesheetEditContext);

export const TimesheetEditContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<TimesheetEditContextState>({
    timesheet: undefined,
    open: false,
    date: undefined,
    openedCount: 0,
  });

  const openDialog = useCallback(
    (date: DateTime, timesheet?: TimesheetDto) => {
      setState(({ openedCount }) => ({ open: true, openedCount: openedCount + 1, timesheet, date }));
    },
    [setState],
  );

  const closeDialog = useCallback(() => {
    setState(({ openedCount }) => ({
      open: false,
      openedCount: openedCount + 1,
      timesheet: undefined,
      date: undefined,
    }));
  }, [setState]);

  const contextState = useMemo(
    () => ({
      ...state,
      openDialog,
      closeDialog,
    }),
    [state, openDialog, closeDialog],
  );

  return <TimesheetEditContext.Provider value={contextState}>{children}</TimesheetEditContext.Provider>;
};

export default TimesheetEditContext;
