import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import useStore from 'store';

import LogoutButton from './components/LogoutButton/LogoutButton';
import UserDetailsButton from './components/UserDetailsButton/UserDetailsButton';
import UserDetailsDialog from './components/UserDetailsDialog/UserDetailsDialog';

const Root = styled(Box)`
  width: 100%;

  background: transparent;
  position: relative;

  border-top: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    padding: ${({ theme }) => theme.spacing(2, 1, 1, 1)};
  }

  ul {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    padding: 0;
    background: rgba(255, 255, 255, 0.1);
    transition: background-color ease-out 100ms;

    &:hover {
      background: ${({ theme }) => theme.palette.secondary.main};
    }

    li {
      color: white;

      svg path {
        fill: white;
      }

      span {
        text-align: center;
      }
    }
  }
`;

export interface UserDetailsProps {}

const UserDetails: FC<UserDetailsProps> = () => {
  const logout = useStore((store) => store.user.logout);
  const { isLoading, mutate } = useMutation(logout);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Root>
      <Divider />
      <UserDetailsButton openUserDialog={() => setOpen(true)} />
      <LogoutButton logout={() => mutate()} loading={isLoading} />
      <UserDetailsDialog open={open} onClose={() => setOpen(false)} />
    </Root>
  );
};

export default UserDetails;
