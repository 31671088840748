import Client from '../../Client';

export enum Endpoints {
  WEEK_SUMMARY = '/export/worklocation/{id}/week/{week}/summary',
  WEEK_SUMMARY_MULTIPLE = '/export/week/{week}/worklocationIds/{worklocationIds}/summarymultiple',
  WEEK_DETAILS = '/export/worklocation/{worklocationId}/week/{week}/details/{departmentId}',
}

/**
 * Fetch week summary for selected worklocation
 *
 * @param worklocationIds
 * @param week Week in YYYY-MM format (2022-51)
 */
export const downloadWeekSummaryMultiple = async (worklocationIds: number[], week: string): Promise<void> => {
  const url = Endpoints.WEEK_SUMMARY_MULTIPLE.replace('{week}', week)
    .replace('{worklocationIds}', worklocationIds.join(','));

  await Client.download(url, { method: 'GET', filename: 'summary-export.csv' });
};

/**
 * Fetch week summary for selected worklocation
 *
 * @param worklocationId
 * @param week Week in YYYY-MM format (2022-51)
 */
export const downloadWeekSummary = async (worklocationId: number, week: string): Promise<void> => {
  const url = Endpoints.WEEK_SUMMARY.replace('{id}', String(worklocationId)).replace('{week}', week);

  await Client.download(url, { method: 'GET', filename: 'summary-export.csv' });
};

/**
 * Fetch summary for selected worklocation/week/department
 *
 * @param worklocationId
 * @param week Week in YYYY-MM format (2022-51)
 * @param departmentId
 */
export const downloadWeekDetails = async (
  worklocationId: number,
  week: string,
  departmentId: number | string,
): Promise<void> => {
  let url = Endpoints.WEEK_DETAILS.replace('{worklocationId}', String(worklocationId))
    .replace('{week}', week)
    .replace('{departmentId}', departmentId === 'all' ? '' : String(departmentId));

  if (url.endsWith('/')) {
    url = url.substring(0, url.length - 1);
  }

  await Client.download(url, { method: 'GET', filename: 'details-export.csv' });
};
