import { DateTime, Duration } from 'luxon';

export const addWeeksToWeek = (week: string, amount: number): string => {
  return DateTime.fromFormat(week, 'kkkk-WW').plus({ weeks: amount }).toFormat('kkkk-WW');
};

export const subWeeksToWeek = (week: string, amount: number): string => {
  return DateTime.fromFormat(week, 'kkkk-WW').minus({ weeks: amount }).toFormat('kkkk-WW');
};

export const getWeekDays = (week: string): DateTime[] => {
  let currentDay = DateTime.fromFormat(week, 'kkkk-WW');
  const weekdays: DateTime[] = [];

  for (let i = 0; i < 7; i++) {
    weekdays.push(currentDay);

    currentDay = currentDay.plus({ day: 1 });
  }

  return weekdays;
};

export const parseDateTime = (time: string): DateTime => {
  const [hours, minutes] = time.split(':');

  return DateTime.fromObject({ hour: Number(hours), minute: Number(minutes) });
};

export const parseDuration = (time: string): Duration => {
  const [hours, minutes] = time.split(':');

  return Duration.fromObject({ hours: Number(hours), minutes: Number(minutes) });
};

export const dateTimeToDuration = (date: DateTime): Duration =>
  Duration.fromObject({
    hours: date.hour,
    minutes: date.minute,
  });

export const durationToDateTime = (duration: Duration): DateTime => {
  const [hours, minutes] = duration.toFormat('h:m').split(':');

  return DateTime.now().set({
    hour: Number(hours),
    minute: Number(minutes),
    second: 0,
    millisecond: 0,
  });
};
