import useSelector from 'store';
import {UserRole} from 'types';

const useIsOttoUser = (): boolean => {
  const roles = useSelector((store) => store.user.user?.roles);

  return (roles ?? []).includes(UserRole.OTTO);
};

export default useIsOttoUser;
