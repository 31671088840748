import { getSliceCreator } from 'store/utils/utils';
import { AccountDto, DepartmentDto, WorklocationDto } from 'types';
import { Api } from 'api';

interface Slice {
  accounts: AccountDto[];
  accountMap: Record<number, AccountDto>;
  worklocationMap: Record<number, WorklocationDto>;
  departmentMap: Record<number, DepartmentDto>;
  loading: boolean;
  fetchAccountsAndWorklocations: () => Promise<void>;
}

export interface AccountsSlice {
  accounts: Slice;
}

const createAccountsSlice = getSliceCreator<Slice>('accounts', (set) => ({
  loading: false,
  accounts: [],
  accountMap: {},
  worklocationMap: {},
  departmentMap: {},
  fetchAccountsAndWorklocations: async () => {
    try {
      set({ loading: true });

      const accounts = await Api.dashboard.fetchAccountsWithWorklocations();

      const accountMap: Record<number, AccountDto> = {};
      const worklocationMap: Record<number, WorklocationDto> = {};
      const departmentMap: Record<number, DepartmentDto> = {};

      accounts.forEach((account) => {
        accountMap[account.id as number] = account;

        account.worklocations?.forEach((worklocation) => {
          worklocationMap[worklocation.id as number] = worklocation;

          worklocation.departments?.forEach((department) => {
            departmentMap[department.id as number] = department;
          })
        });
      });
      set({ loading: false, accounts, accountMap, worklocationMap, departmentMap });
    } catch {
      set({ loading: false });
    }
  },
}));

export default createAccountsSlice;
