import React, { FC } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

export interface TableRowNoDataProps {
  label: string;
  colSpan: number;
}

const TableRowNoData: FC<TableRowNoDataProps> = ({ label, colSpan }) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Typography variant="subtitle1" textAlign="center" textTransform="uppercase" fontSize="16px" padding={8}>
          {label}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default TableRowNoData;
