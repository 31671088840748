import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { FlattenObjectKeys, NestedValueOf } from 'types';

interface Config {
  deps?: any[];
  skipInitial?: boolean;
}

type Effect<T extends Record<string, unknown>, FieldName extends FlattenObjectKeys<T>> = (
  prev: NestedValueOf<T, FieldName> | undefined,
  current: NestedValueOf<T, FieldName>,
) => void;

const useEffectOnFieldChange = <T extends Record<string, unknown>>(
  fieldName: FlattenObjectKeys<T>,
  effect: Effect<T, typeof fieldName>,
  config: Config = {}
) => {
  const previousValue = useRef<NestedValueOf<T, typeof fieldName> | undefined>(undefined);
  const initial = useRef<boolean>(true);

  const { getFieldMeta } = useFormikContext<T>();
  const fieldValue = getFieldMeta<NestedValueOf<T, typeof fieldName>>(fieldName).value;

  useEffect(() => {
    if (initial.current && config.skipInitial) {
      initial.current = false;
      return;
    }

    if (fieldValue !== previousValue.current) {
      effect(previousValue.current, fieldValue);

      previousValue.current = fieldValue;
    }
  }, [fieldValue, previousValue, config.skipInitial, ...(config.deps ?? [])]);
};

export default useEffectOnFieldChange;
