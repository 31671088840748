import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { TimesheetDto } from 'types';
import { parseDateTime } from 'utils';
import yupDateTime from 'utils/yupDateTime';
import { Api } from 'api';
import { FETCH_DEPARTMENT_DETAILS_KEY } from '../../../hooks/useFetchData';

export interface TimesheetEditForm {
  id: number | null;
  start: DateTime;
  end: DateTime;
  break: DateTime;
  duration: DateTime;
  useEmployee: boolean;
  employee: {
    id: number | null;
    firstName: string;
    lastName: string;
    sapId: string;
  };
}

const useForm = (
  timesheet: TimesheetDto | undefined,
  date: DateTime | undefined,
  departmentId: string | undefined,
  closeDialog: () => void,
) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // @TODO Extract query to separate hook. This does too much already!
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, error } = useMutation('upsertTimesheetRow', Api.dashboard.upsertTimesheet, {
    onSuccess: () => queryClient.refetchQueries([FETCH_DEPARTMENT_DETAILS_KEY]),
  });

  const initialValues = useMemo(
    () =>
      ({
        id: timesheet?.id ?? null,
        start: parseDateTime(timesheet?.actualStartTime ?? timesheet?.plannedStartTime ?? '0:00'),
        end: parseDateTime(timesheet?.actualEndTime ?? timesheet?.plannedEndTime ?? '0:00'),
        break: parseDateTime(timesheet?.actualBreakTime ?? timesheet?.plannedBreakTime ?? '0:00'),
        duration: parseDateTime(timesheet?.actualDurationTime ?? timesheet?.plannedDurationTime ?? '0:00'),
        useEmployee: timesheet ?? false,
        employee: {
          id: timesheet?.employee?.id ?? null,
          firstName: timesheet?.employee?.firstName ?? '',
          lastName: timesheet?.employee?.lastName ?? '',
          sapId: timesheet?.employee?.sapId ?? '',
        },
      } as TimesheetEditForm),
    [timesheet],
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        start: yupDateTime().required(t('validation.required')),
        end: yupDateTime().required(t('validation.required')),
        break: yupDateTime()
          .required(t('validation.required'))
          .asDurationLessThanClockTimeDifference(
            yup.ref<DateTime>('start'),
            yup.ref<DateTime>('end'),
            t('validation.breakTooLong'),
          ),
        duration: yupDateTime().required(t('validation.required')),
        employee: yup.object().shape({
          firstName: yup.string(),
          lastName: yup.string(),
          sapId: yup.string(),
        }),
      }),
    [t],
  );

  const onSubmit = useCallback(
    async (form: TimesheetEditForm) => {
      try {
        await mutateAsync({
          id: timesheet?.id ?? null,
          actualStartTime: form.start.toFormat('H:m'),
          actualEndTime: form.end.toFormat('H:m'),
          actualBreakTime: form.break.toFormat('H:m'),
          date: date?.toISODate(),
          serviceOrder: timesheet?.serviceOrder ?? null,
          departmentId: timesheet?.departmentId ?? Number(departmentId),
          function: timesheet?.function ?? null,
          employee:
            timesheet?.employee ??
            (form.useEmployee
              ? {
                  firstName: form.employee.firstName,
                  lastName: form.employee.lastName,
                  sapId: form.employee.sapId,
                }
              : null),
        });

        enqueueSnackbar(t('pages.weekDetails.edit.success'), {
          variant: 'success',
        });

        closeDialog();
      } catch (e) {}
    },
    [departmentId, timesheet, date, mutateAsync, t, enqueueSnackbar, closeDialog],
  );

  const didNotWork = useCallback(
    async () => {
      try {
        await mutateAsync({
          id: timesheet?.id ?? null,
          actualStartTime: '00:00',
          actualEndTime: '00:00',
          actualBreakTime: '00:00',
          date: date?.toISODate(),
          serviceOrder: timesheet?.serviceOrder ?? null,
          departmentId: timesheet?.departmentId ?? Number(departmentId),
          function: timesheet?.function ?? null,
          employee: null,
        });

        enqueueSnackbar(t('pages.weekDetails.edit.success'), {
          variant: 'success',
        });

        closeDialog();
      } catch (e) {}
    },
    [departmentId, timesheet, date, mutateAsync, t, enqueueSnackbar, closeDialog],
  );

  return {
    initialValues,
    validationSchema,
    onSubmit,
    didNotWork,
    isLoading,
    error: error as Error,
  } as const;
};

export default useForm;
