import { getSliceCreator } from 'store/utils/utils';
import { UserDto } from 'types';
import { Api } from 'api';

interface Slice {
  isLoggedIn: boolean;
  shouldChangePassword: boolean;
  accessToken?: string;
  refreshToken?: string;
  user?: UserDto;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;

  setTokens(accessToken: string, refreshToken: string): void;
  setShouldChangePassword(shouldChangePassword: boolean): void;
}

export interface UserSlice {
  user: Slice;
}

const createUserSlice = getSliceCreator<Slice>('user', (set, get, _, globalGet) => ({
  isLoggedIn: false,
  shouldChangePassword: false,
  user: undefined,
  login: async (email, password) => {
    const { token, refreshToken, shouldChangePassword } = await Api.auth.login(email, password);

    set({ isLoggedIn: true, shouldChangePassword, accessToken: token, refreshToken });

    const [user] = await Promise.all([
      Api.auth.fetchCurrentUser(),
      globalGet().accounts.fetchAccountsAndWorklocations(),
    ]);

    set({ isLoggedIn: true, user });
  },
  logout: async () => {
    try {
      if (get().accessToken) {
        await Api.auth.logout();
      }
    } finally {
      set({ isLoggedIn: false, user: undefined, accessToken: undefined, refreshToken: undefined });
    }
  },
  setTokens: (accessToken: string, refreshToken: string) => {
    set({ accessToken, refreshToken });
  },
  setShouldChangePassword: (shouldChangePassword: boolean) => {
    set({ shouldChangePassword });
  },
}));

export default createUserSlice;
