import React, { FC } from 'react';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Avatar from '@mui/material/Avatar';
import SettingsIcon from '@mui/icons-material/Settings';

import useStore from 'store';

export interface UserDetailsButtonProps {
  openUserDialog: () => void;
}

const ActionButtonBase = styled(ButtonBase)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(1)};

  width: 100%;

  &:hover {
    .MuiAvatar-root {
      opacity: 1;
    }
  }
`;

const AvatarRoot = styled.div`
  position: relative;
`;

const StyledAvatar = styled(Avatar)`
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

const ActionAvatar = styled(StyledAvatar)`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  transition: opacity ease-in-out 200ms;
  opacity: 0;

  .MuiSvgIcon-root {
    fill: #888;
  }
`;

const UserDetailsButton: FC<UserDetailsButtonProps> = ({ openUserDialog }) => {
  const user = useStore((store) => store.user.user);

  return (
    <ActionButtonBase onClick={openUserDialog}>
      <AvatarRoot>
        <StyledAvatar />
        <ActionAvatar>
          <SettingsIcon />
        </ActionAvatar>
      </AvatarRoot>
      <Typography color="white">
        {user?.firstName} {user?.lastName}
      </Typography>
    </ActionButtonBase>
  );
};

export default UserDetailsButton;
