import { OrganizationDto } from 'types';

import Client from '../../Client';

export enum Endpoints {
  LIST = '/organization',
  UPSERT = '/organization',
  DETAILS = '/organization/{organizationId}',
  DELETE = '/organization/{organizationId}',
}

/**
 * Fetch organizations
 */
export const fetchOrganizationsList = async (): Promise<OrganizationDto[]> => {
  return (await Client.get(Endpoints.LIST)).data ?? [];
};

/**
 * Upsert organization
 *
 * @param organization Organization data
 */
export const upsertOrganization = async (organization: OrganizationDto): Promise<OrganizationDto> => {
  return (await Client.post(Endpoints.UPSERT, organization)).data ?? [];
};

/**
 * Fetch organization details
 *
 * @param id User ID
 */
export const fetchOrganizationDetails = async (id: string | number): Promise<OrganizationDto> => {
  return (await Client.get(Endpoints.DETAILS.replace('{organizationId}', `${id}`))).data;
};
