import {AxiosError, AxiosRequestConfig} from 'axios';

import store from 'store';

import Client from '../Client';
import {Api, GeneralApiError} from '../index';

export interface RequestWithRetry extends AxiosRequestConfig {
  _retry: boolean;
}

export const handle401 = async (request: RequestWithRetry, error: AxiosError) => {
  if (request.ignoreRefresh) {
    throw new GeneralApiError(error);
  }

  if (request._retry || request.url === Api.auth.Endpoints.REFRESH_TOKEN) {
    return store.getState().user.logout();
  }

  request._retry = true;

  try {
    await Api.auth.refreshToken();
  } catch (e) {
    await store.getState().user.logout();
    throw e;
  }

  return Client.request(request);
};
