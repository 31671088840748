import React, { FC } from 'react';
import useStore from 'store';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
`;

export interface VersionInfoProps {
  className?: string;
}

const VersionInfo: FC<VersionInfoProps> = ({className}) => {
  const { version, buildDate, commitRef } = useStore((store) => store.config.versionInfo);

  return (
    <Root className={className}>
      <Typography variant="caption" color="#c0c0c0">
        {version}
      </Typography>
      <Typography
        title={`Built at ${buildDate}`}
        variant="caption"
        color="#c0c0c0"
        fontSize={10}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {commitRef}
      </Typography>
    </Root>
  );
};

export default VersionInfo;
