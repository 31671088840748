import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';

import 'i18n';

// MUI Roboto Fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Routing from './views/Routing';
import ThemeProvider from './providers/ThemeProvider/ThemeProvider';
import ReactQueryProvider from './providers/ReactQueryProvider/ReactQueryProvider';
import SnackbarProvider from './providers/SnackbarProvider/SnackbarProvider';
import SystemConfigProvider from './providers/SystemConfigProvider/SystemConfigProvider';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import ForceChangePasswordOverlay from './components/ForceChangePasswordOverlay/ForceChangePasswordOverlay';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
  '483ba1b637b6f55f2df7ec35ca6ad663Tz01ODM0OSxFPTE3MDYxNzY0NzM1NDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

const App = () => (
  <Suspense fallback="Loading...">
    <ErrorBoundary bare>
      <ThemeProvider>
        <ErrorBoundary>
          <SnackbarProvider>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <CssBaseline enableColorScheme />
              <ReactQueryProvider>
                <SystemConfigProvider>
                  <BrowserRouter>
                    <Routing />
                  </BrowserRouter>
                  <ForceChangePasswordOverlay />
                </SystemConfigProvider>
              </ReactQueryProvider>
            </LocalizationProvider>
          </SnackbarProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </ErrorBoundary>
  </Suspense>
);

export default App;
