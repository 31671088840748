import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import useWeekDays from 'hooks/useWeekDays/useWeekDays';

import TimesheetsTable from './components/TimesheetsTable/TimesheetsTable';
import useFetchData from '../../hooks/useFetchData';

export interface WeekTableProps {}

const WeekTable: FC<WeekTableProps> = () => {
  const { t } = useTranslation();

  const { week, data, isLoading } = useFetchData();
  const weekDays = useWeekDays(week);
  const [value, setValue] = useState('ALL');

  const handleChange = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  }, []);

  return (
    <Paper>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label={t('pages.weekDetails.tabsAriaLabel')}>
              {weekDays.map((weekDay) => (
                <Tab label={weekDay.toFormat('EEE MM/dd')} value={weekDay.toISODate()} key={weekDay.toISODate()} />
              ))}
              <Tab label="All" value="ALL" />
            </TabList>
          </Box>
          {weekDays.map((weekDay) => (
            <TabPanel value={weekDay.toISODate()} key={weekDay.toISODate()} sx={{ padding: 0 }}>
              <TimesheetsTable
                weekDay={weekDay}
                data={data.filter((timesheet) => timesheet.date?.substring(0, 10) === weekDay.toISODate())}
                loading={isLoading}
              />
            </TabPanel>
          ))}
          <TabPanel value="ALL" sx={{ padding: 0 }}>
            <TimesheetsTable data={data} loading={isLoading} />
          </TabPanel>
        </TabContext>
      </Box>
    </Paper>
  );
};

export default WeekTable;
