import React, { FC } from 'react';
import { GridRowParams } from '@mui/x-data-grid-pro';

import ControlledDataGrid, { ControlledDataGridProps } from '../ControlledDataGrid/ControlledDataGrid';
import useColumns from './hooks/useColumns';
import { OrganizationDto } from '../../types';
import useOrganizationsData from './hooks/useOrganizationsData';

const getRowId = (row: OrganizationDto) => `${row.id}`;

export interface UsersTableProps
  extends Omit<
    ControlledDataGridProps<void>,
    'columns' | 'rows' | 'isLoading' | 'disableColumnFilter' | 'disableColumnMenu'
  > {
  getActions?: (params: GridRowParams<OrganizationDto>) => JSX.Element[];
}

const OrganizationsTable: FC<UsersTableProps> = ({ getActions, ...props }) => {
  const columns = useColumns(getActions);
  const { data = [], isLoading, isFetching } = useOrganizationsData();

  return (
    <ControlledDataGrid
      {...props}
      columns={columns}
      rows={data}
      loading={isLoading || isFetching}
      disableColumnFilter
      disableColumnMenu
      getRowId={getRowId}
    />
  );
};

export default OrganizationsTable;
