import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FormikHelpers } from 'formik/dist/types';
import { useMutation } from 'react-query';

import { Api } from 'api';
import useStore from 'store';

export interface SendPasswordReminderForm {
  password: string;
  confirmPassword: string;
}

const initialValues: SendPasswordReminderForm = {
  password: '',
  confirmPassword: '',
};

const useForm = (token: string) => {
  const { t } = useTranslation();
  const passwordRules = useStore((store) => store.config.passwordRules);

  const {
    mutateAsync,
    isLoading,
    data = undefined,
  } = useMutation(['resetPassword'], async (email: string) => Api.auth.resetPassword(token, email));

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        password: yup
          .string()
          .matches(
            new RegExp(passwordRules.validationRegex),
            t('validation.passwordNotMatchingRules', { passwordRules: passwordRules.validationRegexHint }),
          )
          .oneOf([yup.ref('confirmPassword'), null], t('validation.passwordsNotMatching')),
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], t('validation.passwordsNotMatching')),
      }),
    [t, passwordRules],
  );

  const onSubmit = useCallback(
    async ({ password }: SendPasswordReminderForm, formikHelpers: FormikHelpers<SendPasswordReminderForm>) => {
      try {
        await mutateAsync(password);
      } catch (e) {
        formikHelpers.setFieldError('login', t('errors.invalidCredentials'));
      }
    },
    [mutateAsync, t],
  );

  return {
    initialValues,
    validationSchema,
    onSubmit,
    isLoading,
    result: data,
  } as const;
};

export default useForm;
