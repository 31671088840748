import React, { FC } from 'react';
import styled from 'styled-components';
import { Chip } from '@mui/material';

import { UserRole } from '../../types';

const StyledChip = styled(Chip)`
  margin-inline: ${({ theme }) => theme.spacing(0.25)};

  span {
    text-transform: capitalize;
  }
`;

export const getRoleName = (role: UserRole) => role.replace('ROLE_', '').replaceAll('_', ' ').toLowerCase();

export interface RoleChipProps {
  role: UserRole;
  size?: 'small' | 'medium';
  className?: string;
}

const RoleChip: FC<RoleChipProps> = ({ role, size = 'small', className }) => {
  const roleName = getRoleName(role);

  return <StyledChip className={className} label={roleName} variant="filled" size={size} />;
};

export default RoleChip;
