import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import useStore from 'store';

import { FormikHelpers } from 'formik/dist/types';
import DisabledUserError from 'api/errors/DisabledUserError';

export interface LoginForm {
  login: string;
  password: string;
}

const initialValues: LoginForm = {
  login: '',
  password: '',
};

const useForm = () => {
  const { t } = useTranslation();
  const loginUser = useStore((store) => store.user.login);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        login: yup.string().required(t('validation.required')),
        password: yup.string().required(t('validation.required')),
      }),
    [t],
  );

  const onSubmit = useCallback(
    async ({ login, password }: LoginForm, formikHelpers: FormikHelpers<LoginForm>) => {
      try {
        await loginUser(login, password);
      } catch (e) {
        if (e instanceof DisabledUserError) {
          formikHelpers.setFieldError('password', t('errors.accountDisabled'));
        } else {
          formikHelpers.setFieldError('password', t('errors.invalidCredentials'));
        }
      }
    },
    [loginUser, t],
  );

  return {
    initialValues,
    validationSchema,
    onSubmit,
  } as const;
};

export default useForm;
