import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { FormikHelpers } from 'formik/dist/types';

import { Api } from 'api';
import useStore from 'store';

export interface ChangePasswordFormData {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const useForm = () => {
  const { t } = useTranslation();
  const setShouldChangePassword = useStore((store) => store.user.setShouldChangePassword);
  const { isLoading, mutateAsync } = useMutation(['changePassword'], Api.auth.changePassword);
  const { enqueueSnackbar } = useSnackbar();
  const passwordRules = useStore((store) => store.config.passwordRules);

  const initialValues = useMemo<ChangePasswordFormData>(
    () => ({
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        oldPassword: yup.string().required(t('validation.required')),
        newPassword: yup
          .string()
          .matches(
            new RegExp(passwordRules.validationRegex),
            t('validation.passwordNotMatchingRules', { passwordRules: passwordRules.validationRegexHint }),
          )
          .oneOf([yup.ref('confirmNewPassword'), null], t('validation.passwordsNotMatching'))
          .notOneOf([yup.ref('oldPassword'), null], t('validation.newPasswordSameAsPrevious'))
          .required(t('validation.required')),
        confirmNewPassword: yup
          .string()
          .oneOf([yup.ref('newPassword'), null], t('validation.passwordsNotMatching'))
          .required(t('validation.required')),
      }),
    [passwordRules, t],
  );

  const onSubmit = useCallback(
    async ({ newPassword, oldPassword }: ChangePasswordFormData, helpers: FormikHelpers<ChangePasswordFormData>) => {
      const result = await mutateAsync({ newPassword, oldPassword });

      if (result < 400) {
        setShouldChangePassword(false);
        enqueueSnackbar(t('pages.changePassword.passwordChangedSuccess'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('pages.changePassword.passwordChangedFail'), { variant: 'error' });

        console.log(result);
        if (result === 400) {
          helpers.setFieldError('oldPassword', t('pages.changePassword.passwordChangeFailError'));
        }
      }
    },
    [mutateAsync, t, enqueueSnackbar, setShouldChangePassword],
  );

  return {
    initialValues,
    validationSchema,
    onSubmit,
    isLoading,
  } as const;
};

export default useForm;
