import React, { FC } from 'react';
import { TextField, TextFieldProps } from 'formik-mui';
import { useFormikContext } from 'formik';

export interface FormikTextFieldProps extends Omit<TextFieldProps, 'form' | 'field' | 'meta'> {
  name: string;
}

const FormikTextField: FC<FormikTextFieldProps> = ({ name, ...props }) => {
  const form = useFormikContext();

  const meta = form.getFieldMeta(name);
  const field = form.getFieldProps(name);

  return <TextField {...props} form={form} field={field} meta={meta} />;
};

export default FormikTextField;
