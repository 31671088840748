import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';

import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import ottoLogo from 'assets/images/otto-work-force.png';
import { NavLink } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UserDetails from './components/UserDetails/UserDetails';
import useUserHasRoles from '../../hooks/useUserHasRoles/useUserHasRoles';
import { UserRole } from '../../types';
import VersionInfo from '../VersionInfo/VersionInfo';

export interface LayoutProps {
  children: ReactNode;
}

const Root = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: row;

  main {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    overflow: auto;
    padding: ${({ theme }) => theme.spacing(4)};
  }
`;

const LogoContainer = styled.div`
  width: 100%;
  padding: 15px;
  margin: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
`;

const Links = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  a {
    padding: 16px 24px;
    height: 48px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.primary.contrastText};

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    &.active {
      position: relative;
      background: rgba(255, 255, 255, 0.2);

      &:after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        right: 0;
        top: 4px;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 15px solid white;
      }
    }
  }
`;

const SideBar = styled(Paper)`
  background: ${({ theme }) =>
    `linear-gradient(315deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`};
  border-radius: 0;
  display: flex;
  flex-direction: column;
  width: 240px;

  align-items: center;
`;

const StyledVersionInfo = styled(VersionInfo)`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(1.5)};
  flex-direction: row;

  span {
    margin: ${({ theme }) => theme.spacing(0, 0.5)};
  }
`;

const Layout: FC<LayoutProps> = ({ children }) => {
  const { t } = useTranslation();
  const isAdmin = useUserHasRoles([UserRole.ADMIN]);
  const isOtto = useUserHasRoles([UserRole.OTTO]);

  return (
    <Root>
      <SideBar elevation={3}>
        <ErrorBoundary>
          <LogoContainer>
            <img src={ottoLogo} alt="OTTO WorkForce" />
            <Typography variant="button" color="white" fontSize="1rem" mt={2}>
              Customer Portal
            </Typography>
          </LogoContainer>
          <Links>
            {(isAdmin || isOtto) && <NavLink to="/customer-overview">{t('pages.customerOverview.title')}</NavLink>}
            <NavLink to="/dashboard">{t('pages.dashboard.title')}</NavLink>
            {isAdmin && <NavLink to="/users">{t('pages.users.title')}</NavLink>}
            {isAdmin && <NavLink to="/organizations">{t('pages.organizations.title')}</NavLink>}
          </Links>
          <UserDetails />
        </ErrorBoundary>
      </SideBar>
      <main>
        <ErrorBoundary>{children}</ErrorBoundary>
        <StyledVersionInfo />
      </main>
    </Root>
  );
};

export default Layout;
