import React, { FC, ReactNode } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { blue, deepOrange } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: deepOrange,
  },
});

export interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
);

export default ThemeProvider;
