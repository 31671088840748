import React from 'react';
import { DataGridPro, DataGridProProps, GridEventListener, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { DataGridContext, useDataGridState } from './context/DataGridContext/DataGridContext';
import useClosablePopper from 'components/ClosablePopper/useClosablePopper';

export { useDataGridContext } from './context/DataGridContext/DataGridContext';

export interface ControlledDataGridProps<Extra>
  extends Omit<DataGridProProps, 'rowSelectionModel' | 'onRowSelectionModelChange'> {
  onRowSelectionModelChange?: (selectionModel: GridRowSelectionModel) => void;
  extra?: Extra;
}

const POPPER_HIDDEN_KEY = 'popperShown';

const ControlledDataGrid = <Extra,>({ extra, ...props }: ControlledDataGridProps<Extra>) => {
  const selectionValue = useDataGridState(props.rows, extra);
  const { ClosablePopper, handleTooltipOpen } = useClosablePopper();

  const onColumnHeaderClick: GridEventListener<'columnHeaderClick'> = (_params, event) => {
    const popperShown = localStorage.getItem(POPPER_HIDDEN_KEY);
    if (popperShown !== 'true') {
      handleTooltipOpen(
        event.target,
        'New functionality - Multi-sorting',
        'To use it hold down the Ctrl or Shift (use ⌘ Command on macOS) key while clicking the column header.',
        () => {
          localStorage.setItem(POPPER_HIDDEN_KEY, 'true');
        }
      );
    }
  }

  return (
    <DataGridContext.Provider value={selectionValue}>
      {ClosablePopper}
      <DataGridPro
        {...props}
        rowSelectionModel={selectionValue.selectionModel}
        onRowSelectionModelChange={(rowSelectionModel) => {
          selectionValue.onSelectionModelChange(rowSelectionModel);
          props.onRowSelectionModelChange?.(rowSelectionModel);
        }}
        onColumnHeaderClick={onColumnHeaderClick}
      />
    </DataGridContext.Provider>
  );
};

export default ControlledDataGrid;
