import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import MuiBackdrop from '@mui/material/Backdrop';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';

import SlideUpTransition from '../SlideUpTransition/SlideUpTransition';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { Form, Formik } from 'formik';
import useForm from './hooks/useForm';
import FormikTextField from '../FormikTextField/FormikTextField';

const Backdrop = styled(MuiBackdrop)`
  backdrop-filter: blur(10px);
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;

  margin: ${({ theme }) => theme.spacing(2)};

  & > * {
    margin: ${({ theme }) => theme.spacing(1, 0)};
  }

  & > *:first-child {
    margin: ${({ theme }) => theme.spacing(1, 0, 3, 0)};
  }
`;

const DialogStyledContent = styled.div`
  min-width: 470px;
`;

export interface AdditionalActionsProps {
  loading: boolean;
  onClose: () => void;
}

export interface ChangePasswordOverlayProps {
  title?: string;
  open: boolean;
  onClose: () => void;
  AdditionalActions?: (props: AdditionalActionsProps) => JSX.Element;
  showExpiredRationale?: boolean;
}

const ChangePasswordOverlay = ({ title, open, onClose, AdditionalActions, showExpiredRationale }: ChangePasswordOverlayProps) => {
  const { t } = useTranslation();
  const { validationSchema, initialValues, onSubmit, isLoading } = useForm();

  return (
    <Dialog
      open={open}
      TransitionComponent={SlideUpTransition}
      keepMounted
      components={{
        Backdrop,
      }}
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <ErrorBoundary onClose={onClose}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          <Form>
            <DialogStyledContent>
              <DialogTitle>{title ?? t('pages.changePassword.changePasswordTitle')}</DialogTitle>
              <DialogContent>
                { showExpiredRationale  && <DialogContentText id="alert-dialog-slide-description">
                  {t('pages.changePassword.expiredRationale')}
                </DialogContentText>}
                <FormContent>
                  <FormikTextField
                    label={t('pages.changePassword.oldPassword')}
                    disabled={isLoading}
                    name="oldPassword"
                    type="password"
                  />
                  <FormikTextField
                    label={t('pages.changePassword.newPassword')}
                    disabled={isLoading}
                    name="newPassword"
                    type="password"
                  />
                  <FormikTextField
                    label={t('pages.changePassword.confirmPassword')}
                    disabled={isLoading}
                    name="confirmNewPassword"
                    type="password"
                  />
                </FormContent>
              </DialogContent>
              <DialogActions>
                {AdditionalActions && <AdditionalActions loading={isLoading} onClose={onClose} />}
                <LoadingButton type="submit" loading={isLoading} disabled={isLoading}>
                  {t('pages.changePassword.changePassword')}
                </LoadingButton>
              </DialogActions>
            </DialogStyledContent>
          </Form>
        </Formik>
      </ErrorBoundary>
    </Dialog>
  );
};

export default ChangePasswordOverlay;
