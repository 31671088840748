import { useMutation, useQueryClient } from 'react-query';
import { Api } from 'api';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { FETCH_DEPARTMENT_DETAILS_KEY } from './useFetchData';
import { useCallback } from 'react';

const useActionWithSnackResult = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(
    async (action: 'accept' | 'release', count: number, callable: () => Promise<unknown>) => {
      try {
        await callable();

        enqueueSnackbar(t(`pages.weekDetails.actions.results.${action}.success`, { count }), { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(t(`pages.weekDetails.actions.results.${action}.fail`, { count }), { variant: 'error' });
      }
    },
    [t, enqueueSnackbar],
  );
};

const useAcceptReleaseAction = () => {
  const actionWithResult = useActionWithSnackResult();
  const queryClient = useQueryClient();

  return useMutation(
    ['acceptReleaseTimesheetRow'],
    async ([accept, ...timesheetIds]: [boolean, ...number[]]) => {
      if (accept) {
        await actionWithResult('accept', timesheetIds.length, () => Api.dashboard.acceptTimesheets(...timesheetIds));
      } else {
        await actionWithResult('release', timesheetIds.length, () => Api.dashboard.releaseTimesheets(...timesheetIds));
      }
    },
    {
      onSuccess: () => queryClient.refetchQueries([FETCH_DEPARTMENT_DETAILS_KEY]),
    },
  );
};

export default useAcceptReleaseAction;
