import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GridRowParams, GridActionsCellItemProps } from '@mui/x-data-grid-pro';
import LoadingButton from '@mui/lab/LoadingButton';

import { TimesheetDto } from 'types';

import useAcceptReleaseAction from '../../../../../../hooks/useAcceptReleaseAction';

export type AcceptReleaseActionProps = Omit<GridActionsCellItemProps, 'label' | 'icon'> & {
  gridRowParams: GridRowParams<TimesheetDto>;
  isOttoUser: boolean;
};

const AcceptReleaseAction = ({ isOttoUser, gridRowParams: { row } }: AcceptReleaseActionProps) => {
  const { t } = useTranslation();

  const label = isOttoUser ? t('pages.weekDetails.actions.release') : t('pages.weekDetails.actions.accept');
  const disabled = isOttoUser ? !row.accepted : !!row.accepted;

  const { isLoading, mutate } = useAcceptReleaseAction();
  const handleAcceptRelease = useCallback(() => {
    if (row.id === undefined || row.id === null) {
      throw new Error('Invalid ID parameter for accept/release!');
    }

    mutate([!isOttoUser, row.id]);
  }, [isOttoUser, row.id, mutate]);

  return (
    <LoadingButton size="small" disabled={disabled} loading={isLoading} onClick={handleAcceptRelease}>
      {label}
    </LoadingButton>
  );
};

export default AcceptReleaseAction;
