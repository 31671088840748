import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GridToolbarContainer, GridToolbarProps, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import BusinessIcon from '@mui/icons-material/Business';
import { useNavigate } from 'react-router-dom';

const Actions = styled.div`
  display: flex;

  & > * {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;

const Root = styled(GridToolbarContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: ${({ theme }) => theme.spacing(2)};
`;

export interface DataGridToolbarProps extends GridToolbarProps {}

const DataGridToolbar: FC<DataGridToolbarProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Root>
      <GridToolbarQuickFilter
        debounceMs={250}
        placeholder={t('pages.organizations.actions.search')}
        aria-label={t('pages.organizations.actions.search')}
        variant="outlined"
        size="small"
      />
      <Actions>
        <Button
          startIcon={<BusinessIcon />}
          onClick={() => navigate('/organizations/new')}
          variant="contained"
          size="large"
        >
          {t('pages.organizations.actions.addOrganization')}
        </Button>
      </Actions>
    </Root>
  );
};

export default DataGridToolbar;
