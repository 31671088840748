import { getSliceCreator } from 'store/utils/utils';
import { AccountDto, DepartmentDto, OrganizationGroupDto, Week, WorklocationDto } from 'types';
import { Api } from 'api';

interface Slice {
  accounts: AccountDto[];
  accountMap: Record<number, AccountDto>;
  worklocationMap: Record<number, WorklocationDto>;
  departmentMap: Record<number, DepartmentDto>;
  weeks: Week[];
  groups: OrganizationGroupDto[];
  loading: boolean;
  fetchData: () => Promise<void>;
}

export interface CustomerOverviewSlice {
  customerOverview: Slice;
}

const createCustomerOverviewSlice = getSliceCreator<Slice>('customerOverview', (set) => ({
  loading: false,
  accounts: [],
  groups: [],
  accountMap: {},
  worklocationMap: {},
  departmentMap: {},
  weeks: [],
  fetchData: async () => {
    try {
      set({ loading: true });

      const { accounts, weeks, groups } = await Api.customerOverview.fetchFilterData();

      const accountMap: Record<number, AccountDto> = {};
      const worklocationMap: Record<number, WorklocationDto> = {};
      const departmentMap: Record<number, DepartmentDto> = {};

      accounts.forEach((account) => {
        accountMap[account.id as number] = account;

        account.worklocations?.forEach((worklocation) => {
          worklocationMap[worklocation.id as number] = worklocation;

          worklocation.departments?.forEach((department) => {
            departmentMap[department.id as number] = department;
          })
        });
      });
      set({ loading: false, accounts, accountMap, worklocationMap, departmentMap, weeks, groups});
    } catch {
      set({ loading: false });
    }
  },
}));

export default createCustomerOverviewSlice;
