import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import styled from 'styled-components';

import MuiAvatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Link from '@mui/material/Link';

import useStore from 'store';
import useForm from './hooks/useForm';
import VersionInfo from 'components/VersionInfo/VersionInfo';

const Root = styled.div`
  margin: ${({ theme }) => theme.spacing(8, 4)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Avatar = styled(MuiAvatar)`
  margin: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

const SubmitButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing(3, 0, 2)};
`;

const StyledVersionInfo = styled(VersionInfo)`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(1.5)};
`;

const LoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { initialValues, validationSchema, onSubmit } = useForm();
  const isLoggedIn = useStore((store) => store.user.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/dashboard');
    }
  }, [navigate, isLoggedIn]);

  return (
    <Root>
      <Avatar>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t('common.name')}
      </Typography>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form>
          <Field
            component={TextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="login"
            data-test-id="login-email-field"
            label={t('pages.login.email')}
            name="login"
            autoComplete="email"
          />
          <Field
            component={TextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            data-test-id="login-password-field"
            label={t('pages.login.password')}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <SubmitButton data-test-id="login-sign-in-button" fullWidth variant="contained" color="primary" type="submit">
            {t('pages.login.signInButton')}
          </SubmitButton>
        </Form>
      </Formik>
      <Link component={RouterLink} to="/forgot-password" underline="hover">
        {t('pages.login.forgotPassword')}
      </Link>
      <StyledVersionInfo />
    </Root>
  );
};

export default LoginForm;
