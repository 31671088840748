import { useMutation, useQueryClient } from 'react-query';
import { Api } from 'api';
import { CustomerWeekOverviewDto } from 'types';
import { FETCH_KEY } from 'views/CustomerOverviewPage/hooks/useFetchData';
import { useState } from 'react';

/**
 * Hook for exporting multiple rows
 * It exports the rows by their worklocationId
 * If the worklocationId is not set, it throws an error
 * @param rows 
 * @param week 
 * @returns 
 */
const useRowsExport = (rows: CustomerWeekOverviewDto[], week: string) => {
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false)
    const { isLoading, mutate } = useMutation(
        ['weekDetailsExportMultiple', rows, week],
        async () => {
            const worklocationIds = rows.map<number>(row => {
                if (row.worklocationId === undefined || row.worklocationId === null) {
                    throw new Error('WorklocationId is required');
                }
                return row.worklocationId;
            });

            if (worklocationIds.length === 0) {
                throw new Error('No rows to export');
            }
            setLoading(true)
            Api.exports.downloadWeekSummaryMultiple(worklocationIds, week)
                .then(() => {
                    queryClient.invalidateQueries(FETCH_KEY);
                })
                .finally(() => {setLoading(false)});
        },
    );

  return { isLoading: loading || isLoading, exportData: () => mutate()};
};

export default useRowsExport;
