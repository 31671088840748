import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { OrganizationDto } from 'types';
import { GridColDef, GridRowParams, GridActionsColDef } from '@mui/x-data-grid-pro';

const useColumns = (
  getActions?: (params: GridRowParams<OrganizationDto>) => JSX.Element[],
): GridColDef<OrganizationDto>[] => {
  const { t } = useTranslation();

  return useMemo(() => {
    const columns: (GridColDef<OrganizationDto> | GridActionsColDef<OrganizationDto>)[] = [
      {
        field: 'name',
        flex: 1,
        headerName: t('pages.organizations.columns.name'),
      },
      {
        field: 'users',
        flex: 1,
        headerName: t('pages.organizations.columns.users'),
        valueGetter: (params) => params.row.usersIds?.length,
      },
    ];

    if (getActions) {
      columns.push({
        field: 'actions',
        type: 'actions',
        sortable: false,
        flex: 2,
        headerName: t('pages.organizations.columns.actions'),
        getActions,
      });
    }
    return columns;
  }, [t, getActions]);
};
export default useColumns;
