import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import LoadingButton from '@mui/lab/LoadingButton';

import { useDataGridContext } from 'components/ControlledDataGrid/context/DataGridContext/DataGridContext';
import { TimesheetDto } from 'types';

import useAcceptReleaseAction from '../../../../../../../../hooks/useAcceptReleaseAction';

export interface AcceptAllSelectedButtonProps {}

const AcceptAllSelectedButton: FC<AcceptAllSelectedButtonProps> = () => {
  const { t } = useTranslation();
  const { data, selectionModel = [] } = useDataGridContext<TimesheetDto>();

  const selectionContainsAccepted = useMemo(
    () => data.some((entry) => selectionModel.includes(String(entry.id)) && entry.accepted),
    [data, selectionModel],
  );

  const { isLoading, mutate } = useAcceptReleaseAction();

  return (
    <LoadingButton
      startIcon={<DoneAllIcon />}
      loadingPosition="start"
      loading={isLoading}
      disabled={selectionContainsAccepted || isLoading || selectionModel.length === 0}
      onClick={() => mutate([true, ...selectionModel.map((e) => Number(e))])}
    >
      {t('pages.weekDetails.actions.acceptSelected')}
    </LoadingButton>
  );
};

export default AcceptAllSelectedButton;
