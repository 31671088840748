import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { FormikHelpers } from 'formik/dist/types';
import { Api } from 'api';
import { useMutation } from 'react-query';

export interface SendPasswordReminderForm {
  email: string;
}

const initialValues: SendPasswordReminderForm = {
  email: '',
};

const useForm = () => {
  const { t } = useTranslation();

  const { mutateAsync, isLoading, data = false } = useMutation(['sendPasswordReset'], Api.auth.sendPasswordReminder);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email().required(t('validation.required')),
      }),
    [t],
  );

  const onSubmit = useCallback(
    async ({ email }: SendPasswordReminderForm, formikHelpers: FormikHelpers<SendPasswordReminderForm>) => {
      try {
        await mutateAsync(email);
      } catch (e) {
        formikHelpers.setFieldError('login', t('errors.invalidCredentials'));
      }
    },
    [mutateAsync, t],
  );

  return {
    initialValues,
    validationSchema,
    onSubmit,
    isLoading,
    result: data,
  } as const;
};

export default useForm;
