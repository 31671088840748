import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { CellDefinition } from 'components/TableRowPlaceholder/TableRowPlaceholder';
import TableRowsPlaceholder from 'components/TableRowsPlaceholder/TableRowsPlaceholder';
import TableRowNoData from 'components/TableRowNoData/TableRowNoData';

interface TableContentProps<T extends object> {
  data: T[];
  isLoading: boolean;
  cells: CellDefinition[];
  extractKey: (value: T) => string;
  Row: ComponentType<{ value: T }>;
}

const TableContent = <T extends object>({ data, cells, isLoading, Row, extractKey }: TableContentProps<T>) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <TableRowsPlaceholder cells={cells} rows={5} />;
  }

  if (data.length === 0) {
    return <TableRowNoData label={t('common.noData')} colSpan={14} />;
  }

  return (
    <>
      {data.map((value) => (
        <Row key={extractKey(value)} value={value} />
      ))}
    </>
  );
};

export default TableContent;
