import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { UserDto, UserRole } from 'types';
import { Api, GeneralApiError } from 'api';
import { FETCH_USERS_QUERY_KEY } from 'components/UsersTable/hooks/useUsersData';
import { FETCH_ORGANIZATIONS_QUERY_KEY } from 'components/OrganizationsTable/hooks/useOrganizationsData';
import { useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik/dist/types';
import useStore from '../../../../../store';
import { FETCH_USER_DETAILS_QUERY } from '../../../types';

export interface UserFormData {
  email: string;
  firstName: string;
  lastName: string;
  roles: string[];
  password: string;
  active: boolean;
  confirmPassword: string;
  organizations: {
    id?: number;
  }[];
  currentWeek: number;
}

const useForm = (user?: UserDto) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { isLoading, mutateAsync } = useMutation(['upsertUser'], Api.user.upsertUser, {
    onSuccess: async ({ id }) => {
      await queryClient.invalidateQueries([
        FETCH_USERS_QUERY_KEY,
        FETCH_ORGANIZATIONS_QUERY_KEY,
        FETCH_USER_DETAILS_QUERY,
      ]);
      await queryClient.refetchQueries([FETCH_USER_DETAILS_QUERY]);

      if (user === undefined) {
        navigate(`/users/${id}`);
      }
    },
  });

  const { enqueueSnackbar } = useSnackbar();
  const passwordRules = useStore((store) => store.config.passwordRules);

  const initialValues = useMemo(
    () => ({
      email: user?.email ?? '',
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      roles: user?.roles ?? [UserRole.USER],
      password: '',
      confirmPassword: '',
      active: user?.active ?? true,
      organizations: user?.organizations ?? [],
      currentWeek: user?.currentWeek ?? 0
    }),
    [user],
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email(t('validation.invalidEmail')).required(t('validation.required')),
        firstName: yup.string().required(t('validation.required')),
        lastName: yup.string().required(t('validation.required')),
        roles: yup.array(),
        password: yup
          .string()
          .matches(
            new RegExp(passwordRules.validationRegex),
            t('validation.passwordNotMatchingRules', { passwordRules: passwordRules.validationRegexHint }),
          )
          .oneOf([yup.ref('confirmPassword'), null], t('validation.passwordsNotMatching')),
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], t('validation.passwordsNotMatching')),
        organizations: yup.array(),
        currentWeek: yup.number().required()
      }),
    [passwordRules.validationRegex, passwordRules.validationRegexHint, t],
  );

  const onSubmit = useCallback(
    async (formValues: UserFormData, helpers: FormikHelpers<UserFormData>) => {
      try {
        await mutateAsync({
          ...formValues,
          id: user?.id,
          language: 'en',
          active: formValues.active,
          organizationsIds: formValues.organizations
            .filter((o) => o.id !== undefined)
            .map((organization) => organization.id as number),
          organizations: undefined,
          currentWeek: formValues.currentWeek,
        });
        enqueueSnackbar(t('pages.userDetails.results.success', formValues), { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(t('pages.userDetails.results.fail', formValues), { variant: 'error' });

        if (e instanceof GeneralApiError && e.getAxiosError()?.response?.data?.code === 4001) {
          helpers.setFieldError('email', t('pages.userDetails.results.failInvalidEmail', formValues));
        } else if (e instanceof GeneralApiError && e.getAxiosError()?.response?.data?.code === 4002) {
          helpers.setFieldError('email', t('pages.userDetails.results.failAlreadyExists', formValues));
        } else if (e instanceof GeneralApiError && e.getAxiosError()?.response?.data?.code === 4003) {
          const parsedMessage = JSON.parse(e.getAxiosError()?.response?.data?.message);
          if (parsedMessage?.email) {
            helpers.setFieldError('email', t(parsedMessage?.email?.join(), formValues));
          }
        }
      }
    },
    [mutateAsync, user, t, enqueueSnackbar],
  );

  return {
    initialValues,
    validationSchema,
    onSubmit,
    isLoading,
  } as const;
};

export default useForm;
