import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const SuccessMessage = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.success.main};
`;

const FailureMessage = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.error.main};
`;

export interface ResultProps {
  result: boolean;
}

const Result: FC<ResultProps> = ({ result }) => {
  const { t } = useTranslation();

  if (result) {
    return (
      <SuccessMessage variant="body2" textAlign="center">
        {t('pages.forgotPassword.passwordChangedSuccess')}
        <Link component={RouterLink} to="/login" underline="hover">
          {' '}
          {t('pages.forgotPassword.passwordChangedLinkSuccess')}
        </Link>
      </SuccessMessage>
    );
  }

  return (
    <FailureMessage variant="body2" textAlign="center">
      {t('pages.forgotPassword.passwordChangedFail')}
      <Link component={RouterLink} to="/forgot-password" underline="hover">
        {' '}
        {t('pages.forgotPassword.passwordChangedLinkFail')}
      </Link>
    </FailureMessage>
  );
};

export default Result;
