import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import store from 'store';
import { handle401, RequestWithRetry } from './errorHandlers';
import { GeneralApiError } from '../errors';

export const requestInterceptor = (requestConfig: AxiosRequestConfig) => {
  const accessToken = store.getState().user.accessToken;

  if (accessToken && !requestConfig.skipJWT) {
    requestConfig.headers = {
      ...(requestConfig.headers ?? {}),
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return requestConfig;
};

export const responseInterceptor = (response: AxiosResponse) => response;

export const responseErrorInterceptor = (error: AxiosError) => {
  const originalRequest = error.config as RequestWithRetry;

  if (
    error.response?.status !== undefined &&
    (error.config.errorInterceptorIgnoredCodes ?? []).includes(error.response?.status)
  ) {
    return;
  }

  switch (error.response?.status) {
    case 401:
      return handle401(originalRequest, error);
    default:
      throw GeneralApiError.wrapIfAxios(error);
  }
};
