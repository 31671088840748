import React, { FC } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import styled from 'styled-components';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

import useStore from 'store';

import {RouteParams} from "../../types";

export interface BreadcrumbsProps {}

const Root = styled(Paper)`
  flex: 1;
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

const Breadcrumbs: FC<BreadcrumbsProps> = () => {
  const { week, worklocation, department } = useParams<RouteParams>();
  const worklocationMap = useStore((store) => store.accounts.worklocationMap);
  const departmentMap = useStore((store) => store.accounts.departmentMap);

  return (
    <Root>
      <MuiBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
          Dashboard
        </Link>
        <Link underline="hover" color="inherit" component={RouterLink} to={`/dashboard/${week}`}>
          {week}
        </Link>
        <Link underline="hover" color="inherit" component={RouterLink} to={`/dashboard/${week}/worklocation/${worklocation}/department/all`}>
          {worklocationMap[Number(worklocation)]?.name ?? 'INVALID'}
        </Link>
        <Link underline="hover" color="inherit" component={RouterLink} to={`/dashboard/${week}/worklocation/${worklocation}/department/${department}`}>
          {department === 'all' ? 'All' : departmentMap[Number(department)]?.name ?? 'INVALID'}
        </Link>
      </MuiBreadcrumbs>
    </Root>
  );
};

export default Breadcrumbs;
