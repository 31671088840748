import React, { FC } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';

import Layout from 'components/Layout/Layout';

import { useParams } from 'react-router-dom';
import CustomerOverviewFilters from './components/CustomerOverviewFilters/CustomerOverviewFilters';
import SummaryTable from './components/SummaryTable/SummaryTable';
import ReportWithWeekBreadcrump from 'components/ReportWithWeekBreadcrump/BreadcrumbWithWeek';
import { Form as FormType } from './types';
import { CheckedCustomerOverviewProvider } from 'context/CheckedCustomerOverview/CheckedCustomerOverview';

const StyledForm = styled(Form)`
  max-width: 1380px;
  align-self: start;
  flex: 1;

  display: grid;
  grid-template-rows: auto auto 1fr auto;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.spacing(2)};
`;

export interface CustomerOverviewPageProps {}

const CustomerOverviewPage: FC<CustomerOverviewPageProps> = () => {
  const { week } = useParams<{ week?: string }>();

  return (
    <Layout>
      <Formik<FormType>
        initialValues={{
          account: null,
          worklocation: null,
          week: week ?? null,
          group: { id: -1, name: 'All Customers' },
        }}
        onSubmit={() => {}}
      >
        <CheckedCustomerOverviewProvider>
          <StyledForm>
            <ReportWithWeekBreadcrump<FormType> reportName={'Customer overview'} />
            <CustomerOverviewFilters />
            <SummaryTable />
          </StyledForm>
        </CheckedCustomerOverviewProvider>
      </Formik>
    </Layout>
  );
};

export default CustomerOverviewPage;
