import React, { ChangeEventHandler, FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import Upload from '@mui/icons-material/Upload';
import LoadingButton from '@mui/lab/LoadingButton';

import { Api, KnownCodeError } from 'api';

import { FETCH_DASHBOARD_SUMMARY_KEY } from '../../../../hooks/useFetchData';

const Button = styled(LoadingButton)`
  margin-left: ${({ theme }) => theme.spacing(2)};
` as typeof LoadingButton;

export interface ImportButtonProps {}

const ImportButton: FC<ImportButtonProps> = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [uploadCount, setUploadCount] = useState(0);

  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(['uploadHours'], Api.imports.uploadHours, {
    onSuccess: ({ addedRows }) => {
      enqueueSnackbar(t('pages.dashboard.import.success', { count: addedRows }), { variant: 'success' });
      queryClient.refetchQueries([FETCH_DASHBOARD_SUMMARY_KEY]);
    },
    onError: (error: Error) => {
      const reason = KnownCodeError.getMessage(error, 'pages.dashboard.import.errors', i18n, (message) =>
        JSON.parse(message),
      );
      enqueueSnackbar(t('pages.dashboard.import.fail', { reason }), { variant: 'error' });
    },
  });

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    async (event) => {
      const file = event.target?.files?.item(0);

      if (file === null || file === undefined) {
        enqueueSnackbar(t('pages.dashboard.import.noFileSelected'), { variant: 'warning' });
        return;
      }

      try {
        await mutateAsync(file);
      } finally {
        setUploadCount((old) => old + 1);
      }
    },
    [t, enqueueSnackbar, mutateAsync, setUploadCount],
  );

  return (
    <Button variant="contained" endIcon={<Upload />} component="label" loading={isLoading} disabled={isLoading}>
      {t('pages.dashboard.import.button')}
      <input key={String(uploadCount)} hidden accept="text/csv" type="file" onChange={onChange} />
    </Button>
  );
};

export default ImportButton;
