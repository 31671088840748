import { UserRole } from 'types';
import useStore from '../../store';

const useUserHasRoles = (roles: UserRole[] | undefined, every = false) => {
  const assignedRoles = useStore((store) => store.user.user?.roles) ?? [];

  if (!roles || roles.length === 0) {
    return true;
  }

  const hasRequiredRole = (role: string) => roles.includes(role as UserRole);

  return every ? assignedRoles.every(hasRequiredRole) : assignedRoles.some(hasRequiredRole);
};

export default useUserHasRoles;
