import React, { FC, useId } from 'react';
import { Collapse } from '@mui/material';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import FormikTextField from 'components/FormikTextField/FormikTextField';

import { TimesheetEditForm } from '../../hooks/useForm';
import FormikSwitchField from '../../../../../../components/FormikSwitchField/FormikSwitchField';
import DialogContentText from '@mui/material/DialogContentText';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const EmployeeContainer = styled.div`
  display: grid;
  padding-top: ${({ theme }) => theme.spacing(2)};
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacing(2)};
`;

export interface EmployeeDetailsProps {
  disabled: boolean;
}

const EmployeeDetails: FC<EmployeeDetailsProps> = ({ disabled }) => {
  const { t } = useTranslation();
  const {
    values: { useEmployee },
  } = useFormikContext<TimesheetEditForm>();
  const id = useId();

  return (
    <Root>
      <SwitchWrapper>
        <FormikSwitchField name="useEmployee" id={id} disabled={disabled} />
        <DialogContentText fontWeight="bold" component="label" htmlFor={id}>
          {t('pages.weekDetails.edit.employeeSection')}
        </DialogContentText>
      </SwitchWrapper>
      <Collapse in={useEmployee}>
        <EmployeeContainer>
          <FormikTextField
            disabled={disabled}
            label={t('pages.weekDetails.edit.firstName')}
            name="employee.firstName"
          />
          <FormikTextField disabled={disabled} label={t('pages.weekDetails.edit.lastName')} name="employee.lastName" />
          <FormikTextField disabled={disabled} label={t('pages.weekDetails.edit.employeeId')} name="employee.sapId" />
        </EmployeeContainer>
      </Collapse>
    </Root>
  );
};

export default EmployeeDetails;
