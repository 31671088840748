import {TFunction} from 'react-i18next';

import TranslatedError from './TranslatedError';

class DisabledUserError extends TranslatedError {
  constructor() {
    super('errors.accountDisabled');
  }

  getTranslatedMessage = (t: TFunction) => t(this.message);
}

export default DisabledUserError;
