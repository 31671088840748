import React, { FC, useMemo } from 'react';
import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';

export interface PlaceholderCellProps {
  float?: 'left' | 'right';
  width?: number;
  widthVariance?: number;
}

const PlaceholderCell: FC<PlaceholderCellProps> = ({ width, widthVariance = 0, float }) => {
  const config = useMemo(
    () => ({
      width: width ? Math.random() * widthVariance + width : undefined,
      align: float,
      sx: float
        ? {
            float,
          }
        : undefined,
    }),
    [float, width, widthVariance],
  );

  return (
    <TableCell align={config.align}>
      <Skeleton width={config.width} sx={config.sx} />
    </TableCell>
  );
};

export default PlaceholderCell;
