import Client from '../../Client';

export enum Endpoints {
  HOURS = '/import/hours',
}

/**
 * Import hours CSV file
 *
 * @param file File to upload
 */
export const uploadHours = async (file: File): Promise<{ addedRows: number }> => {
  return (await Client.postForm(Endpoints.HOURS, { file })).data;
};
