import React, { useCallback, useMemo } from 'react';
import { GridColDef, GridRowParams, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CustomerWeekOverviewDto } from 'types';
import useIsOttoUser from 'hooks/useIsOttoUser/useIsOttoUser';
import TableCell from '@mui/material/TableCell';
import styled from 'styled-components';
import ExportAccepted from '../components/CustomerOverviewFilters/components/ExportAccepted/ExportAccepted';

const UnerLineStyle = styled.div`
  text-decoration: underline;
  text-decoration-color: #2196f3;
  text-decoration-thickness: 1.25px;
  cursor: pointer;
`;

const useColumns = ( week: string ): GridColDef<CustomerWeekOverviewDto>[] => {
  const { t } = useTranslation();
  const isOttoUser = useIsOttoUser();
  const navigate = useNavigate();

  const navigateToDashboard = useCallback((overview: CustomerWeekOverviewDto) => {
    navigate(`/dashboard/${week}/account/${overview?.accountId}/worklocation/${overview?.worklocationId}`);
  }, [navigate, week]);

  return useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF
      },
      {
        field: 'accountName',
        flex: 1,
        headerName: 'Account',
        hideable: false,
        valueGetter: (params) => {
          return params.row.accountName
        },
      },
      {
        field: 'worklocationName',
        flex: 1,
        headerName: 'Worklocation',
        hideable: false,
        valueGetter: (params) => {
          return params.row.worklocationName
        },
      },
      {
        field: 'plannedHours',
        minWidth: 80,
        headerName: 'Planned',
        hideable: false,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => {
          return <TableCell align="right" onClick={() => navigateToDashboard(params.row)}><UnerLineStyle>{params.row.plannedHours}</UnerLineStyle></TableCell>
        }
      },
      {
        field: 'confirmedHours',
        minWidth: 80,
        headerName: 'Confirmed',
        hideable: false,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => {
          return <TableCell align="right" onClick={() => navigateToDashboard(params.row)}><UnerLineStyle>{params.row.confirmedHours}</UnerLineStyle></TableCell>
        },
      },
      {
        field: 'status',
        minWidth: 80,
        headerName: 'Status',
        hideable: false,
        headerAlign: 'right',
        align: 'right',
        type: 'singleSelect',
        valueOptions: ['open', 'accepted', 'exported'],
        valueGetter: (params) => {
          return params.row.status;
        },
      },
      {
        field: 'actions',
        type: 'actions',
        sortable: false,
        hideable: false,
        width: 140,
        headerName: t('pages.weekDetails.table.columns.actions'),
        headerAlign: 'right',
        align: 'right',
        getActions: (params: GridRowParams<CustomerWeekOverviewDto>) => {
          const actions = [
            <ExportAccepted overview={params.row} week={week} />
          ];

          return actions;
        },
      },
    ] as GridColDef<CustomerWeekOverviewDto>[],
    [t, isOttoUser, week, navigateToDashboard],
  );
};

export default useColumns;
