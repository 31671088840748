import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GridRowParams, GridActionsCellItemProps } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import { DateTime } from 'luxon';

import { TimesheetDto } from 'types';

import { useTimesheetEditContext } from '../../../../../TimsheetEdit/context/TimesheetEditContext';

export type AcceptReleaseActionProps = Omit<GridActionsCellItemProps, 'label' | 'icon'> & {
  gridRowParams: GridRowParams<TimesheetDto>;
};

const EditTimesheetRowAction = ({ gridRowParams: { row } }: AcceptReleaseActionProps) => {
  const { t } = useTranslation();
  const { openDialog } = useTimesheetEditContext();

  const handleEditRow = useCallback(() => {
    if (row.date === undefined) {
      throw new Error('Invalid date!');
    }

    openDialog(DateTime.fromISO(row.date), row);
  }, [openDialog, row]);

  return (
    <Button size="small" disabled={!!row.accepted} onClick={handleEditRow}>
      {t('pages.weekDetails.actions.edit')}
    </Button>
  );
};

export default EditTimesheetRowAction;
