import { useFormikContext } from 'formik';
import { useQuery } from 'react-query';

import { CustomerWeekOverviewDto } from 'types';
import { Api } from 'api';

import { Form } from '../types';

export const FETCH_KEY = 'customerOverviewSummary';

const EMPTY_DATA: CustomerWeekOverviewDto[] = [];

const useFetchData = () => {
  const {
    values: { week, group },
  } = useFormikContext<Form>();

  const { isLoading, data = EMPTY_DATA } = useQuery<CustomerWeekOverviewDto[]>(
    [FETCH_KEY, week, group],
    async () => {
      if (week === null || week === '' || group.id === null || group.id === undefined) {
        return EMPTY_DATA;
      }

      return Api.customerOverview.fetchWeekSummary(week, group);
    },
  );

  return {
    data,
    isLoading,
  } as const;
};

export default useFetchData;
