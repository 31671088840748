import React, { FC } from 'react';
import styled from 'styled-components';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import SummaryGrid from './SummaryGrid';
import { useFormikContext } from 'formik';
import { Form } from 'views/CustomerOverviewPage/types';

const Root = styled.div`
  flex: 1;
  flex-direction: column;

  & > * {
    width: 100%;
  }
`;

export interface SummaryTableProps {}

const SummaryTable: FC<SummaryTableProps> = () => {
  const {
    values: { week }
  } = useFormikContext<Form>();

  return (
    <Root>
      <TableContainer component={Paper}>
        { week && <SummaryGrid week={week} /> }
      </TableContainer>
    </Root>
  );
};

export default SummaryTable;
